/*
*
* SASS config
*
*/

// Colors
$primary: #0072CF;
$accent: #41AD49;
$accent2: #EA7125;
$error: #E50000;
$text: rgba(0, 0, 0, 0.87);
$warn: rgb(250, 229, 0);
$linkText: #0072CF;
$darkPrimary: #0059B6;
$lightGray: #F5F5F4;
$lightHeaderText: rgba(0, 0, 0, 0.54);

// Media breakpoints
$mobile-width: 600px;
$tablet-width: 768px;
$desktop-width: 1024px;

:export {
  primary: $primary;
  accent: $accent;
  accent2: $accent2;
  error: $error;
  text: $text;
  lightHeaderText: $lightHeaderText;
  warn: $warn;
  linkText: $linkText;
  darkPrimary: $darkPrimary;
  lightGray: $lightGray;
  mobileWidth: $mobile-width;
  tabletWidth: $tablet-width;
  desktopWidth: $desktop-width;
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}