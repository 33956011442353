/*
*
* AppRoot Styles
*
*/
@import 'utils/config/sassconfig';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: Roboto, PT Sans, Arial, sans-serif;
  color: #242424;
  font-size: 100%;
  background-color: #FAFAFA;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0;
}

::-ms-clear, ::-ms-reveal {
  display: none;
}

a, a:visited {
  color: $darkPrimary;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

:root { // exports css variables as sass variables for @frontend/common
  --primary: #{$primary};
  --accent: #{$accent};
  --accent2: #{$accent2};
  --error: #{$error};
  --text: #{$text};
  --warn: #{$warn};
  --linkText: #{$linkText};
  --lightGray: #{$lightGray};
}

.grecaptcha-badge { // Prevents recaptcha from appearing on all pages
  visibility: hidden;
}

@media print {
  .hideOnPrint {
    display: none !important;
  }
}