/*
*
* ViewOptionChange Styles
*
*/
.AccountOption_page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AccountOption_container {
  max-width: 1200px;
  width: 100%;
}

.AccountOption_name {
  margin: 10px 0 20px 0;
  text-align: center;
  font-size: 1.3em;
}