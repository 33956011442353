@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
/*
*
* SASS config
*
*/

/*
*
* PageNotFound Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__1Bmj2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }

.styles_shrug__1e8um {
  color: #0072CF;
  margin: 0;
  padding: 0;
  font-weight: bold;
  animation: styles_bounce__3RHdF 650ms ease-out;
  font-size: 65px; }

@keyframes styles_bounce__3RHdF {
  0% {
    font-size: 50px; }
  40% {
    font-size: 75px;
    transform: rotate(5deg); }
  60% {
    font-size: 55px; }
  75% {
    font-size: 70px;
    transform: rotate(-10deg); }
  90% {
    font-size: 60px; }
  100% {
    font-size: 65px;
    transform: rotate(0deg); } }

.styles_message__3ZHs_ {
  font-size: 14px; }

@media (min-width: 600px) {
  .styles_shrug__1e8um {
    font-size: 150px; }
  @keyframes styles_bounce__3RHdF {
    0% {
      font-size: 120px; }
    40% {
      font-size: 180px;
      transform: rotate(5deg); }
    60% {
      font-size: 130px; }
    75% {
      font-size: 160px;
      transform: rotate(-10deg); }
    90% {
      font-size: 145px; }
    100% {
      font-size: 150px;
      transform: rotate(0deg); } }
  .styles_message__3ZHs_ {
    font-size: 20px; } }

/*
*
* Header Styles
*
*/
/*
*
* SASS config
*
*/

.styles_navContainer__3VvB4 {
  width: 100%; }
  @media (max-width: 767px) {
    .styles_navContainer__3VvB4 {
      position: fixed;
      z-index: 1100; } }

.styles_navHeader__jYN53,
.styles_navHeaderShift__3niPu,
.styles_publicNavHeader__W8KtR,
.styles_left__3HJTh,
.styles_right__2OILH,
.styles_menuIcon__3QZX3,
.styles_darkerBackground__1OXmy,
.styles_browserWarningContainer__3e4T0,
.styles_browserWarningClose__1i2me {
  display: flex;
  align-items: center; }

.styles_navHeader__jYN53,
.styles_navHeaderShift__3niPu,
.styles_publicNavHeader__W8KtR {
  height: 80px;
  background-color: #FAFAFA;
  justify-content: space-between; }
  @media print {
    .styles_navHeader__jYN53,
    .styles_navHeaderShift__3niPu,
    .styles_publicNavHeader__W8KtR {
      box-shadow: none; } }

.styles_publicNavHeader__W8KtR {
  justify-content: center; }

.styles_menuIcon__3QZX3,
.styles_notificationIcon__27kdP {
  padding: 0px 15px 0px 5px;
  height: 100%;
  cursor: pointer; }
  @media print {
    .styles_menuIcon__3QZX3,
    .styles_notificationIcon__27kdP {
      display: none; } }

.styles_darkerBackground__1OXmy {
  height: 80px;
  background-color: #FAFAFA; }

.styles_logoHeader__3cnt1 {
  margin-top: 5px; }
  @media (max-width: 767px) {
    .styles_logoHeader__3cnt1 {
      display: none; } }

.styles_vertLine__tYnwe {
  border-left: 1px solid rgba(0, 0, 0, 0.24);
  height: 60px;
  margin: 0 20px 0 20px; }
  @media (max-width: 767px) {
    .styles_vertLine__tYnwe {
      border-left: 0;
      height: 60px;
      margin: 0 0 0 10px; } }

.styles_pageTitle__1IJql {
  font-size: 32px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
  text-align: center; }

.styles_browserWarningContainer__3e4T0 {
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fae500;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); }

.styles_browserWarningClose__1i2me {
  justify-content: center;
  cursor: pointer;
  padding: 0 5px; }

.styles_sideLogo__2HkWN {
  display: inline;
  display: initial;
  height: 35px; }
  @media (max-width: 767px) {
    .styles_sideLogo__2HkWN {
      display: none; } }

@media (max-width: 1023px) {
  .styles_languageSelector__1GuCn {
    height: 100%;
    display: flex;
    align-items: center; } }

/************** TRANSITIONS **************/
.styles_navHeader__jYN53 {
  transition: all .5s; }

@media (min-width: 1024px) {
  .styles_navHeaderShift__3niPu {
    transition: margin-left .5s;
    margin-left: 250px; } }

/*
*
* Sidenav Styles
*
*/
/*
*
* SASS config
*
*/

.styles_sideNavContainer__3-_km {
  display: flex;
  flex-direction: column;
  height: 100%; }

.styles_sideNavContainer__3-_km .styles_topMenu__3uagt {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 150px;
  height: 100%;
  padding-top: 20px;
  background: linear-gradient(#F8F8F8 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #F8F8F8 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll; }
  @media (max-width: 767px) {
    .styles_sideNavContainer__3-_km .styles_topMenu__3uagt {
      padding-top: 0; } }

@media (max-width: 767px) {
  .styles_hamburger__29yUc {
    display: none; } }

.styles_greetings__3sE5W {
  display: flex;
  padding-bottom: 10px; }

.styles_sideNavContainer__3-_km .styles_bottomMenu__2l-k8 {
  margin-top: auto;
  margin-bottom: 10px; }

.styles_sideNavContainer__3-_km button {
  margin: 3px 3px 0 0;
  align-self: flex-end; }

.styles_bottom__33miY {
  margin: 0; }
  .styles_bottom__33miY .styles_helpContainer__2aM0k,
  .styles_bottom__33miY .styles_previousLoginContainer__1BuGk {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .styles_bottom__33miY .styles_helpContainer__2aM0k p {
    display: flex; }

.styles_helpContainer__2aM0k a,
.styles_helpContainer__2aM0k p {
  font-size: 16px;
  margin: 2px; }

.styles_aoName__3hvPS {
  margin: 0 auto 0 10px; }
  @media (max-width: 767px) {
    .styles_aoName__3hvPS {
      margin-top: 0; } }

.styles_aoName__3hvPS h3,
.styles_helpContainer__2aM0k h4,
.styles_previousLoginContainer__1BuGk h5,
.styles_version__NZYVB h5 {
  text-transform: uppercase;
  font-size: small;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 0;
  font-weight: normal; }

.styles_previousLoginContainer__1BuGk p,
.styles_version__NZYVB p {
  font-size: small; }

.styles_aoName__3hvPS p {
  margin: 0;
  font-size: x-large;
  white-space: normal; }

.styles_hide__2wsXC {
  display: none; }

/*
*
* NotificationsDrawer Styles
*
*/
/*
*
* SASS config
*
*/

.styles_closeIcon__1S8R6 {
  text-align: right; }

.styles_notificationsTitle__2iCa_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;
  padding: 10px; }

.styles_notificationContainer__bmBOn {
  width: 400px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  color: #000 !important;
  color: initial !important;
  cursor: pointer; }
  @media (max-width: 767px) {
    .styles_notificationContainer__bmBOn {
      width: 100%; } }

.styles_notificationContainer__bmBOn:hover {
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none; }

.styles_notificationContainerNotViewed__2tKO2 {
  background: rgba(153, 204, 255, 0.3); }

.styles_fromTime__3D8KR {
  font-size: small; }

.styles_notificationStatus__1zUIr {
  margin-top: 3px; }

.styles_notificationStatus__1zUIr,
.styles_fromTime__3D8KR {
  color: rgba(0, 0, 0, 0.54); }

.styles_iconContainer__2cumA {
  margin-right: 10px;
  font-size: x-large; }

.styles_notificationType__31Rk7,
.styles_iconContainer__2cumA {
  color: #0072CF; }

.styles_notificationHeader__7lVqW {
  font-size: large;
  font-weight: bold;
  display: flex; }

.styles_notificationsText__24Jek {
  padding: 9px; }

.styles_noNotifications__3u43Y {
  padding: 9px;
  margin: 20px 0; }

/*
*
* AppTour Styles
*
*/
/*
*
* SASS config
*
*/

.styles_overlay__5PKpb {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-backdrop-filter: brightness(50%) blur(6px);
          backdrop-filter: brightness(50%) blur(6px);
  z-index: 9998;
  overflow: hidden; }

@-moz-document url-prefix() {
  .styles_overlay__5PKpb {
    background: rgba(128, 128, 128, 0.5); } }

.styles_navCircleHidden__29Mma,
.styles_notifCircleHidden__1mJXJ,
.styles_navCircleLoaded__1rs5u,
.styles_notifCircleLoaded__x2AW6 {
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_navCircleHidden__29Mma {
  top: -100px;
  left: -100px;
  visibility: hidden; }

.styles_notifCircleHidden__1mJXJ {
  top: -100px;
  right: -100px;
  visibility: hidden; }

.styles_navCircleLoaded__1rs5u,
.styles_notifCircleLoaded__x2AW6 {
  background: white;
  height: 100px;
  width: 100px;
  position: absolute;
  z-index: 9999;
  border-radius: 50%;
  visibility: visible; }

.styles_notifCircleLoaded__x2AW6 {
  top: -18px;
  right: -18px;
  transition: top .75s, right .75s ease; }

.styles_navCircleLoaded__1rs5u {
  top: -18px;
  left: -18px;
  transition: top .75s, left .75s ease; }

.styles_iconWithText__rOvWL {
  display: flex;
  align-items: center;
  position: relative; }
  @media (max-width: 767px) {
    .styles_iconWithText__rOvWL {
      flex-direction: column; } }

.styles_iconWithText__rOvWL > span {
  font-size: 72px;
  margin-right: 50px; }
  @media (max-width: 767px) {
    .styles_iconWithText__rOvWL > span {
      margin-right: 0px;
      font-size: 52px; } }

.styles_iconWithText__rOvWL > img {
  height: auto;
  width: 46px;
  margin-right: 74px; }
  @media (max-width: 767px) {
    .styles_iconWithText__rOvWL > img {
      margin-right: 0px; } }

.styles_content__1cujm {
  margin: 0px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  transition: none; }
  @media (max-width: 767px) {
    .styles_content__1cujm {
      margin: 0px; } }

.styles_title__2CijA {
  font-weight: 400;
  text-align: center; }

.styles_bottomNav__3clPl {
  display: flex;
  flex-direction: column; }

.styles_stepperNav__3byZs,
.styles_skipNav__1jq5l {
  width: 100%; }

.styles_skipNav__1jq5l {
  margin-top: 15px;
  display: flex;
  justify-content: space-between; }

.styles_welcomeImage__1DEKX {
  width: 175px; }
  @media (max-width: 767px) {
    .styles_welcomeImage__1DEKX {
      width: 50px; } }

.styles_transactionsIcon1__3eRtC {
  margin-right: 43px; }
  @media (max-width: 767px) {
    .styles_transactionsIcon1__3eRtC {
      margin: 0px; } }

.styles_transactionsIcon2__2hQWt {
  margin-right: 25px; }
  @media (max-width: 767px) {
    .styles_transactionsIcon2__2hQWt {
      margin: 0px; } }

/*
*
* FormWrapper Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__YBhHS,
.styles_body__4uXbW {
  display: flex;
  flex-direction: column; }
  @media (min-width: 1024px) {
    .styles_container__YBhHS,
    .styles_body__4uXbW {
      align-items: center; } }

.styles_body__4uXbW {
  margin: 25px; }
  @media (min-width: 1024px) {
    .styles_body__4uXbW {
      width: 350px; } }

.styles_logo__AzPiX {
  width: 100%;
  margin-bottom: 35px;
  align-self: center; }

.styles_instructions__17Vag {
  margin: 15px 15px 0 15px; }

/*
*
* Banner Styles
*
*/
/*
*
* SASS config
*
*/

.styles_banner__B24-H {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  width: 100%; }

.styles_bannerMessages__NYQZN {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 15px 25px;
  max-width: 75%; }
  @media (max-width: 767px) {
    .styles_bannerMessages__NYQZN {
      max-width: 100%; } }

/*
*
* Login Styles
*
*/
/*
*
* SASS config
*
*/

.styles_linkContainer__ddUU_ {
  display: flex;
  align-items: center;
  margin-top: 20px;
  grid-column-gap: 3px;
  column-gap: 3px; }

.styles_linkDivider__2g5Xm {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  grid-template-areas: 'dividerLeft dividerText dividerRight';
  text-align: center;
  align-items: center;
  margin: 30px 0; }

.styles_dividerLeft__1xsfv {
  grid-area: dividerLeft; }

.styles_dividerText__15G8c {
  grid-area: dividerText; }

.styles_dividerRight__323hi {
  grid-area: dividerRight; }

.styles_button__2wMwt {
  margin-top: 30px;
  width: 100%; }

.styles_recaptcha__2IIB2 {
  margin: auto;
  text-align: center;
  margin-top: 25px;
  width: 300px;
  color: #767676; }

.styles_maintenanceMsgContainer__GVI-K {
  display: flex;
  justify-content: center;
  margin-bottom: 25px; }

.styles_maintenanceMsgContainer__GVI-K h1 {
  font-size: x-large; }

.styles_maintenanceMsg__d6Yim {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 15px 25px;
  max-width: 75%; }
  @media (max-width: 767px) {
    .styles_maintenanceMsg__d6Yim {
      max-width: 100%; } }

.styles_financialAdvisorLoginButton__1KNfr {
  color: #EA7125; }
  .styles_financialAdvisorLoginButton__1KNfr a, .styles_financialAdvisorLoginButton__1KNfr a:link, .styles_financialAdvisorLoginButton__1KNfr a:visited {
    color: inherit; }

.styles_buttons__2x3yK {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; }

.styles_recaptcha__3uzBf {
  margin: auto;
  text-align: center;
  margin-top: 25px;
  width: 300px;
  color: #767676; }

/*
*
* SASS config
*
*/

.styles_container__1xfH1 {
  display: grid;
  grid-template-columns: 60%;
  padding: 25px;
  margin-top: -50px;
  justify-content: center; }
  @media (max-width: 767px) {
    .styles_container__1xfH1 {
      grid-template-columns: 100%; } }

.styles_container__1xfH1 h2 {
  font-weight: 500;
  margin: 25px 0px;
  font-size: medium; }

.styles_container__1xfH1 p {
  margin-bottom: 5px; }

.styles_container__1xfH1 p,
.styles_otherInfo___Kn3z a {
  margin-left: 35px; }

.styles_headerContainer__24uhh {
  text-align: center; }

.styles_otherInfo___Kn3z a {
  margin-bottom: 20px; }

.styles_formContainer__3efjB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.styles_formContainer__3efjB h1,
.styles_formContainer__3efjB p {
  align-self: flex-start; }

.styles_formContainer__3efjB form {
  max-width: 300px;
  margin-top: 25px; }

.styles_formContainer__3efjB form > div {
  margin-bottom: 15px; }

.styles_logo__9Q2my {
  text-align: center; }

.styles_recaptcha__2wXqE {
  margin: auto;
  text-align: center;
  margin-top: 25px;
  width: 300px;
  color: #767676; }

/*
*
* Register Styles
*
*/
/*
*
* SASS config
*
*/

.styles_newAccountSubLanguage__2Y7N9 {
  margin-top: 1em;
  margin-left: 35px; }

.styles_buttons__q30xs {
  margin-top: 30px;
  min-width: 125px; }

.styles_passwordRequirements__3NIss,
.styles_termsAndConditions__1-mmo {
  margin-top: 15px; }

.styles_termsAndConditionsContainer__2g0t2 {
  display: flex;
  flex-direction: column;
  align-items: center; }

/*
*
* ResendEmail Styles
*
*/
/*
*
* SASS config
*
*/

.styles_button__2AN_C {
  margin-top: 30px;
  width: 100%; }

.styles_mainText__1qZ3p {
  font-size: large;
  line-height: 150%; }

.styles_supportText__3xc-V {
  font-size: medium;
  margin-top: 50px;
  color: rgba(0, 0, 0, 0.54); }

.styles_header__3vLeg {
  line-height: 150%; }

.styles_buttons__F6tAx {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; }

.styles_divPadding__E24Nx {
  margin-top: 30px; }

.styles_buttons__F6tAx {
  margin-top: 30px;
  display: flex;
  justify-content: space-between; }

/*
*
* ValidateEmail Styles
*
*/
.styles_container__2-14p {
  display: flex;
  flex-direction: column; }

.styles_loadingContainer__1SBO2 {
  margin-top: 25%;
  height: 100%;
  width: 100%; }

.styles_message__17kdV {
  text-align: center; }

/*
*
* SASS config
*
*/

.styles_buttons__2dD83 {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; }

.styles_recaptcha__11uRE {
  margin: auto;
  text-align: center;
  margin-top: 25px;
  width: 300px;
  color: #767676; }

/*
*
* AccountEdit Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__9H6Dh {
  display: flex;
  flex-direction: column; }

.styles_title__3a5Nc {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3em; }

.styles_sectionsContainer__1THha {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1em;
  margin-bottom: 1em; }
  @media (max-width: 767px) {
    .styles_sectionsContainer__1THha {
      margin-right: -5px; } }

.styles_section__2BQ2H {
  display: flex;
  flex: 1 0 300px;
  flex-direction: column;
  margin-right: 1em;
  min-width: 300px;
  max-width: 50%; }
  @media (max-width: 767px) {
    .styles_section__2BQ2H {
      margin-right: 5px;
      max-width: 100%; } }

@media (max-width: 767px) {
  .styles_section__2BQ2H:last-child > .styles_sectionItem__30SPH:last-child {
    border-bottom: none; } }

.styles_sectionItem__30SPH {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding-bottom: 2em; }
  @media (max-width: 767px) {
    .styles_sectionItem__30SPH {
      padding-bottom: 1em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3); } }

.styles_sectionTitle__lBzik {
  margin-top: 1em;
  text-align: center;
  text-transform: uppercase; }

.styles_sectionSubTitle__FRQAg {
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  font-size: small;
  text-transform: uppercase;
  margin-bottom: 1em; }

.styles_emptySubtitle__3TByw {
  min-height: 1rem; }

.styles_titleInfoContainer__6Z2AK {
  display: flex; }

.styles_infoColumn__3jbYU {
  padding: 1.2em 0.3em 1em; }

.styles_learnMoreButton__1GAc1 {
  padding: 1em 0.5em 1em;
  white-space: nowrap; }

.styles_infoIconByTitle__LO1eF {
  padding: 1.2em 0 0 1.2em; }

.styles_detailsRow__1bDw7 {
  display: flex; }

.styles_navButtons__1KTMB {
  display: flex;
  margin: 20px;
  justify-content: center; }

.styles_copyAddress__1TIIl {
  padding: 10px;
  text-align: center; }

.styles_aboveButtonsMessage__jzHCQ {
  padding: 20px;
  text-align: center; }

/*
*
* SASS config
*
*/

.styles_learnMoreInfoContainer__1WY_w {
  padding: 20px;
  padding-top: 10px; }

.styles_closeIcon__3H4qu {
  text-align: right; }

.styles_learnMoreInfo__1qRm4 {
  font-size: small;
  margin-left: 2em;
  margin-right: 2em;
  padding-bottom: 25px; }

.styles_learnMoreInfoHeader__3jGyI {
  text-align: center;
  margin: 10px 0px; }

.styles_levelSelectionContainer__1DW07 {
  padding: 10px; }

.styles_levelSelectionContainer__1DW07 h2,
.styles_review__eSa5G h2,
.styles_review__eSa5G h3 {
  text-transform: uppercase;
  text-align: center; }

.styles_levelSelectionContainer__1DW07 table {
  border-collapse: collapse;
  width: 100%; }

.styles_levelSelectionContainer__1DW07 table td {
  border: 1px solid lightgray;
  padding: 10px;
  text-align: center; }

.styles_levelSelectionContainer__1DW07 table thead,
.styles_levelSelectionContainer__1DW07 table tbody tr:nth-child(even) {
  background-color: #f2f8ff; }

.styles_levelSelectionContainer__1DW07 table tr:first-child td {
  border-top: 0; }

.styles_levelSelectionContainer__1DW07 table tr td:first-child {
  border-left: 0; }

.styles_levelSelectionContainer__1DW07 table tr:last-child td {
  border-bottom: 0; }

.styles_levelSelectionContainer__1DW07 table tr td:last-child {
  border-right: 0; }

/*
*
* SASS config
*
*/

.styles_questionAndAnswer__1rH5r {
  padding: 10px;
  font-weight: bold; }

.styles_helpText__2B0of {
  font-weight: normal;
  padding: 10px; }

.styles_rsaQuestionsButton__3eZzw {
  text-align: center;
  padding-bottom: 20px; }

/*
*
* FirstTermsAndConditions Styles
*
*/
.styles_container__2BmdB {
  margin: 40px;
  text-align: center; }

.styles_content__1DNP9 {
  margin-bottom: 1em;
  text-align: left; }

.styles_navButtons__3IFv3 {
  display: flex;
  margin: 20px;
  justify-content: center;
  padding-bottom: 20px; }

/*
*
* SecondTermsAndConditions Styles
*
*/
.styles_container__3NGQY {
  margin: 40px;
  text-align: center; }

.styles_content__2J3OE {
  text-align: left; }

.styles_navButtons__2ytc4 {
  display: flex;
  margin: 20px;
  justify-content: center;
  padding-bottom: 20px; }

/*
*
* LpoaAccount Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__3vY45 {
  margin: 25px; }
  @media (max-width: 767px) {
    .styles_container__3vY45 {
      margin: 5px; } }

.styles_logo__15YL4 {
  padding: 25px;
  text-align: center; }

.styles_failedRSA__3V9b7 {
  text-align: center;
  color: #E50000;
  padding: 2em; }

.styles_accountCreatedContainer__cVQEs {
  text-align: center;
  padding: 25px; }

.styles_accountCreatedTitle__R0s3S {
  text-transform: uppercase; }

.styles_newAccountNumber__3-hVd {
  color: var(--primary); }

.styles_button__3DlmR {
  margin-right: 10px;
  margin-top: auto; }

.styles_navButtons__1Hu6b {
  display: flex;
  margin: 20px;
  justify-content: center;
  padding-bottom: 20px; }

/*
*
* CreateIpAccount Styles
*
*/
.styles_passwordRequirements__1Ep-r {
  margin: 15px 0; }

.styles_termsAndConditionsContainer__33kE6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0px; }

/*
*
* InterestedPartiesActivation Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__5WGH3 {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 1023px) {
    .styles_container__5WGH3 {
      height: 1250px; } }

.styles_container__5WGH3 h2 {
  font-size: large;
  text-align: center; }

.styles_formsContainer__1_6me {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'logo' 'loginForm' 'createAccountForm';
  padding: 25px; }
  @media (min-width: 1024px) {
    .styles_formsContainer__1_6me {
      grid-template-columns: 45% auto 45%;
      grid-template-areas: 'logo logo logo' 'loginForm . createAccountForm';
      max-width: 800px; } }

.styles_formsContainerLoginOnly__1JspZ {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'logo' 'loginForm';
  padding: 25px; }

.styles_createAccountForm__3-HXs {
  grid-area: createAccountForm;
  margin-top: 50px; }
  @media (min-width: 1024px) {
    .styles_createAccountForm__3-HXs {
      margin-top: 0px; } }

.styles_loginForm__2gizT {
  grid-area: loginForm; }
  @media (min-width: 1024px) {
    .styles_loginForm__2gizT {
      margin-top: 0px; } }

.styles_logo__2Rs89 {
  grid-area: logo;
  justify-self: center;
  margin-bottom: 25px; }

.styles_button__7xS00 {
  margin-top: 30px;
  width: 100%; }

.styles_learnMoreBtn__3dbo1 {
  display: flex;
  justify-content: center;
  margin-top: 25px; }

.styles_hideFormContainer__3dVEG {
  max-height: 0px;
  transition: all .5s ease;
  opacity: 0; }

.styles_showFormContainer__pfrjc {
  max-height: 1000px;
  transition: all .5s ease;
  opacity: 1; }

.styles_learnMoreContainer__7b0Jm {
  padding: 20px;
  display: flex;
  flex-direction: column; }

.styles_learnMoreContainer__7b0Jm button {
  align-self: flex-end; }

.styles_learnMoreContainer__7b0Jm h3 {
  text-align: center; }

.styles_learnMoreContainer__7b0Jm p {
  margin-left: 250px;
  margin-right: 250px; }
  @media (max-width: 1023px) {
    .styles_learnMoreContainer__7b0Jm p {
      margin-left: 50px;
      margin-right: 50px; } }
  @media (max-width: 767px) {
    .styles_learnMoreContainer__7b0Jm p {
      margin-left: 0px;
      margin-right: 0px; } }

/*
*
* LoginIp Styles
*
*/
.styles_linkContainer__1VV9o {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px; }

/*
*
* AccountDetails Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__2kT6S {
  width: 100%;
  max-width: 1400px;
  margin-top: 25px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-columns: 100%;
  grid-template-areas: 'closedAccountBanner' 'accountDetailsCard' 'upcomingTransactionsCard' 'transactionsCard'; }

.styles_accountDetailsContainer__1BT_c {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: 'accountDetailsLeft accountDetailsRight';
  padding: 0 20px 20px 20px; }
  @media (max-width: 1023px) {
    .styles_accountDetailsContainer__1BT_c {
      grid-template-columns: 100%;
      grid-template-areas: 'accountDetailsLeft' 'accountDetailsRight'; } }

.styles_accountDetailsLeft__34ThT {
  margin-right: 10px; }
  @media (max-width: 1023px) {
    .styles_accountDetailsLeft__34ThT {
      margin-right: 0; } }

.styles_accountDetailsRight__2LZCy {
  margin-left: 10px; }
  @media (max-width: 1023px) {
    .styles_accountDetailsRight__2LZCy {
      margin-left: 0; } }

.styles_closedAccountBanner__3CY2b {
  grid-area: closedAccountBanner; }

.styles_accountDetailsCard__2lRnQ {
  grid-area: accountDetailsCard; }

.styles_upcomingTransactionsCard__2fyR0 {
  grid-area: upcomingTransactionsCard; }

.styles_transactionsCard__1_1mx {
  grid-area: transactionsCard; }

.styles_accountDetailsLeft__34ThT {
  grid-area: accountDetailsLeft; }

.styles_accountDetailsRight__2LZCy {
  grid-area: accountDetailsRight; }

.styles_closedAccountBanner__3CY2b {
  background-color: #fae500;
  height: 30px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_accountType__1RbPu,
.styles_accountTypeInterestedParty__oDIra {
  background-color: #0072CF;
  color: #FFFFFF;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px 4px 0px 0px;
  text-transform: capitalize;
  letter-spacing: 3px; }

.styles_accountTypeInterestedParty__oDIra {
  background-color: #41AD49; }

.styles_combinedDetails__ME2w1 {
  margin-top: 40px;
  margin-bottom: 5px; }

.styles_beneContact__U8aZq {
  margin-top: 25px; }

.styles_valueDetails__NsbEZ {
  margin-left: 10px; }

.styles_transactionBtns__3wwWD {
  display: flex;
  justify-content: space-around; }

.styles_transactionBtns__3wwWD button {
  color: #0072CF;
  text-decoration: none;
  font-weight: bold;
  font-size: small;
  margin-right: 3px;
  margin-left: 3px; }

.styles_successorsContainer__3QPNj {
  align-self: flex-end;
  margin-left: 10px;
  margin-bottom: 10px; }

.styles_currentAllocation__7y2Ud {
  margin-top: 20px;
  padding-bottom: 20px; }
  @media (max-width: 1023px) {
    .styles_currentAllocation__7y2Ud {
      border-left: none;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 20px;
      margin-bottom: 20px; } }

.styles_topLabel__2twhc {
  color: rgba(0, 0, 0, 0.54);
  font-size: medium;
  margin-bottom: 3px;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .styles_topLabel__2twhc {
      font-size: small; } }

.styles_beneficiaryName__29cAl,
.styles_accountNumber__1Q0tc,
.styles_balance__3vLpZ,
.styles_pprPercentage__YRbJM {
  font-size: xx-large;
  margin: 0;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .styles_beneficiaryName__29cAl,
    .styles_accountNumber__1Q0tc,
    .styles_balance__3vLpZ,
    .styles_pprPercentage__YRbJM {
      font-size: x-large;
      margin-bottom: 10px; } }

.styles_option__4owZX,
.styles_optionChangesRemaining__1g2kn {
  font-size: x-large;
  margin: 0;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .styles_option__4owZX,
    .styles_optionChangesRemaining__1g2kn {
      margin-bottom: 10px; } }

.styles_openedDate__1cNya {
  color: rgba(0, 0, 0, 0.54);
  font-size: medium;
  margin-bottom: 3px; }
  @media (max-width: 767px) {
    .styles_openedDate__1cNya {
      font-size: small; } }

/*
*
* BeneContact Styles
*
*/
/*
*
* SASS config
*
*/

.styles_beneAddressRow__3vr2L {
  grid-area: beneAddressRow; }

.styles_buttonIcons__YCUQL {
  grid-area: buttonIcons; }

.styles_beneAddressRowContainer__NMS1T {
  display: grid;
  grid-template-columns: 90% 10%;
  grid-template-areas: 'beneAddressRow buttonIcons';
  align-items: center; }

.styles_beneAddressRow__3vr2L {
  display: flex;
  align-items: center;
  margin: 15px 0;
  height: 50px; }

.styles_beneAddress__3oPjD {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
  white-space: break-spaces; }
  @media (max-width: 767px) {
    .styles_beneAddress__3oPjD {
      flex-direction: column; } }

.styles_buttonIcons__YCUQL {
  text-align: center; }

/*
*
* BeneContactCard Styles
*
*/
/*
*
* SASS config
*
*/

.styles_benContactForm__3SOR9 {
  width: 100%; }

.styles_form__3GY5a, .styles_readOnlyAddress__1ofOw {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 90%; }
  @media (max-width: 767px) {
    .styles_form__3GY5a, .styles_readOnlyAddress__1ofOw {
      width: 100%; } }

.styles_readOnlyAddress__1ofOw {
  align-items: center;
  width: 100%; }
  @media (min-width: 1024px) {
    .styles_readOnlyAddress__1ofOw {
      min-height: 290px;
      justify-content: center; } }

.styles_titleContainer__383Qk {
  display: flex;
  align-items: center;
  align-self: flex-start; }

.styles_titleContainer__383Qk span {
  font-size: 18px;
  margin-left: 5px; }

.styles_tabs__QSyH7 {
  margin-bottom: 10px; }

/*
*
* AllocationChart Styles
*
*/
/*
*
* SASS config
*
*/

.styles_chartContainer__38FG5 {
  width: 100%;
  height: 550px; }
  @media (max-width: 1023px) {
    .styles_chartContainer__38FG5 {
      margin-top: 10px; } }
  @media (max-width: 767px) {
    .styles_chartContainer__38FG5 {
      height: 350px;
      margin-bottom: -50px; } }

.styles_allocationChart__3Dytx {
  transform: translate(50%, 45%) scale(1.75);
  height: 100%;
  cursor: pointer; }
  @media (max-width: 767px) {
    .styles_allocationChart__3Dytx {
      transform: translate(50%, 40%) scale(0.9); } }

/*
*
* CurrentAllocation Styles
*
*/
/*
*
* SASS config
*
*/

.styles_tooltipsContainer__2OUqW {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around; }

.styles_tooltipContainer__2-s64 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  padding: 0 15px 10px 15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px; }

.styles_tooltip__1Xlei {
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: space-between; }

.styles_header__3_qt3 {
  font-weight: bold; }

.styles_data__1OBiw {
  align-self: flex-end; }

.styles_categoryNameContainer__355KV {
  text-align: center; }

.styles_categoryName__2cYhN {
  text-align: center;
  font-weight: bold;
  font-size: large;
  margin-bottom: 15px; }

.styles_noFundsViewContainer__2Pefg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  height: 100%;
  width: 100%; }

.styles_noFundsView__3AVot {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px; }

.styles_closedHeader__2f9Gr,
.styles_noFundsView__3AVot,
.styles_makeContributionText__2DL6e {
  margin: 10px 0; }

.styles_closedHeader__2f9Gr,
.styles_noFundsView__3AVot {
  font-size: xx-large;
  font-weight: bold; }
  @media (max-width: 767px) {
    .styles_closedHeader__2f9Gr,
    .styles_noFundsView__3AVot {
      font-size: medium; } }

.styles_makeContributionText__2DL6e {
  font-size: x-large; }
  @media (max-width: 767px) {
    .styles_makeContributionText__2DL6e {
      font-size: small; } }

/*
*
* SASS config
*
*/

.styles_learnMoreInfoContainer__1F0Jz {
  padding: 20px;
  padding-top: 10px; }

.styles_divider__2yINL {
  border: 1px solid #F5F5F4; }

.styles_closeIcon__jPboV {
  text-align: right; }

.styles_learnMoreInfo__10ELY {
  font-size: small;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 25px; }

.styles_learnMoreInfoHeader__iKKtL {
  text-align: center;
  margin: 10px 0px; }

/*
*
* PrrCalculator Styles
*
*/
/*
*
* SASS config
*
*/

.styles_topBarContainer__3rNtG {
  padding-left: 1em;
  padding-right: 0; }
  @media (max-width: 767px) {
    .styles_topBarContainer__3rNtG {
      padding-left: 5px;
      padding-right: 0; } }

.styles_topBar__c2f_n {
  display: flex; }

.styles_titleColumn__1k0q8,
.styles_closeIconColumn__2hPA4 {
  flex-direction: row; }

.styles_titleColumn__1k0q8 {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 1em;
  padding-left: 10px; }

.styles_closeIconColumn__2hPA4 {
  margin-left: auto; }

.styles_prrCalculator__1qW12 {
  max-width: 300px;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto; }

.styles_beneficiary__3IGCc {
  margin-top: 1em; }

.styles_data__2Ag-Z,
.styles_dataTILE__z5ttH {
  font-weight: bold;
  margin-bottom: 0em;
  font-size: x-large;
  text-transform: uppercase; }

.styles_label__p1yuY,
.styles_labelTILE__2qdwC {
  color: rgba(0, 0, 0, 0.3);
  font-size: small;
  margin-bottom: 1em; }

@media (max-width: 1023px) {
  .styles_dataTILE__z5ttH {
    font-size: large; } }

.styles_rorContainer__2H-BZ {
  margin-top: 1em; }

.styles_rorTitle__3V8rW {
  font-size: x-small; }

.styles_ppr__25aMl {
  display: flex;
  justify-content: center;
  font-size: xx-large; }
  .styles_ppr__25aMl span {
    font-size: xxx-large; }
    @media (max-width: 767px) {
      .styles_ppr__25aMl span {
        font-size: xx-large; } }
  @media (max-width: 1023px) {
    .styles_ppr__25aMl {
      font-size: x-large; } }
  @media (max-width: 767px) {
    .styles_ppr__25aMl {
      font-size: large; } }

.styles_positiveReturn__1ZSLe {
  color: #41AD49; }

.styles_negativeReturn__6vuE6 {
  color: var(--error); }

.styles_pprNotAvailable__32Z4A {
  font-size: large; }

.styles_buttons__xfwGj {
  margin-top: 2em;
  display: flex;
  flex-direction: column; }

.styles_NA__Njjyt {
  font-size: x-large; }

/*
*
* Details Styles
*
*/
/*
*
* SASS config
*
*/

.styles_pprPercentageContainer__3u5sH {
  display: flex;
  justify-content: flex-end; }

.styles_pprPercentage__H6ihe {
  grid-area: pprPercentage; }

.styles_buttonIcons__2uGdG {
  grid-area: buttonIcons; }

.styles_ppr__3hNDR {
  display: flex;
  justify-content: flex-end;
  font-size: xx-large;
  align-items: center; }
  .styles_ppr__3hNDR span {
    font-size: xxx-large; }
    @media (max-width: 767px) {
      .styles_ppr__3hNDR span {
        font-size: xx-large; } }
  @media (max-width: 1023px) {
    .styles_ppr__3hNDR {
      font-size: x-large; } }
  @media (max-width: 767px) {
    .styles_ppr__3hNDR {
      font-size: large; } }

.styles_positiveReturn__2nPul {
  color: #41AD49; }

.styles_negativeReturn__1Fn-X {
  color: var(--error); }

.styles_pprNotAvailable__3PMdN {
  font-size: large; }

.styles_accountDetails__2fgAX {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: 'beneficiaryNameSection accountNumber' 'totalBalance pprContainer';
  grid-row-gap: 50px;
  width: 100%; }

.styles_beneficiaryNameSection__2iZpF {
  grid-area: beneficiaryNameSection; }

.styles_accountNumber__1fCEG {
  grid-area: accountNumber; }

.styles_totalBalance__15hmS {
  grid-area: totalBalance; }

.styles_pprContainer__3Yq7b {
  grid-area: pprContainer; }

.styles_scholarshipEntity__ciBte {
  display: flex;
  align-items: center; }

.styles_scholarshipEntity__ciBte span {
  margin-right: 10px; }

.styles_scholarshipEntity__ciBte p {
  font-size: small; }

.styles_beneficiaryNameSection__2iZpF,
.styles_totalBalance__15hmS {
  text-align: left; }

.styles_accountNumber__1fCEG,
.styles_pprContainer__3Yq7b {
  text-align: right; }

/*
*
* PromoCode Styles
*
*/
/*
*
* SASS config
*
*/

.styles_promoCodeInput__3v8X3 {
  display: flex;
  align-items: center; }

.styles_successMessage__Tb5Lj {
  display: flex;
  align-items: center;
  color: #41AD49; }

/*
*
* AccountDetails Styles
*
*/
/*
*
* SASS config
*
*/

.styles_successorTitle__2--gF {
  grid-area: successorTitle; }

.styles_successorName__8T3Dh {
  grid-area: successorName; }

.styles_buttonIcons__iWXf9 {
  grid-area: buttonIcons; }

.styles_successorRemove__ZDNMl {
  grid-area: successorRemove; }

.styles_successorEdit__1pItX {
  grid-area: successorEdit; }

.styles_primarySuccessor2__1FKpp, .styles_secondarySuccessor2__3v3Wj {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  grid-template-areas: 'successorTitle successorName buttonIcons';
  min-height: 48px; }

.styles_buttonIcons__iWXf9 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: 'successorRemove successorEdit';
  text-align: center; }

.styles_successorTitle__2--gF {
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  align-self: center; }

.styles_successorName__8T3Dh {
  text-align: right;
  align-self: center; }

.styles_addSuccessorContainer__Q0jHA {
  text-align: center; }

.styles_primarySuccessorActionIcons__3fxVN,
.styles_secondarySuccessorActionIcons__2RSuS {
  display: flex;
  position: absolute;
  top: -18px; }

.styles_primarySuccessorDoubleIcon__eASvk {
  right: -80px; }

.styles_primarySuccessorSingleIcon__2vniZ {
  right: -40px; }

.styles_secondarySuccessorDoubleIcon__2EWH_ {
  right: -90px; }

.styles_secondarySuccessorSingleIcon__3cAId {
  right: -50px; }

.styles_form__7pRR2 {
  display: flex;
  flex-direction: column; }

.styles_checkbox__3xKty {
  margin-top: 15px; }

@media (min-width: 1024px) {
  .styles_modal__3tSXd {
    width: 550px; } }

.styles_formMessage__1g3e6 {
  font-size: small;
  margin-bottom: 15px; }

.styles_datePickerContainer__3S0HS {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

.styles_successorsContainer__2nP0K {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'secondarySuccessor' 'primarySuccessor';
  grid-row-gap: 15px; }

.styles_primarySuccessor__1BPkT {
  grid-area: secondarySuccessor; }

.styles_secondarySuccessor__2RnPt {
  grid-area: primarySuccessor; }

.styles_primarySuccessor__1BPkT,
.styles_secondarySuccessor__2RnPt {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }

/*
*
* UpcomingTransactions Styles
*
*/
.styles_tableContainer__37m2U {
  width: 100%;
  margin-top: 10px; }

.styles_Transaction_container__Q67qw {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  margin: 10px; }

.styles_transactionNavigation__scERe {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; }

.styles_transactionTitle__2b-9v {
  margin-top: 18px; }

.styles_transactionTableWrapper__3tG5G {
  text-align: center; }

.styles_transactionTable__29vX6 {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px; }

.styles_transactionTable__29vX6 td,
.styles_transactionTable__29vX6 th {
  border: 0;
  padding: 8px;
  text-align: left; }

/*
*
* ViewOptionChange Styles
*
*/
.AccountOption_page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AccountOption_container {
  max-width: 1200px;
  width: 100%;
}

.AccountOption_name {
  margin: 10px 0 20px 0;
  text-align: center;
  font-size: 1.3em;
}
.styles_Transaction_container__1772Z {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  margin: 10px; }

.styles_transactionNavigation__2Mg8W {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; }

.styles_transactionTitle__2a-mi {
  margin-top: 18px; }

.styles_transactionTableWrapper__3rKAX {
  text-align: center; }

.styles_transactionTable__3EFCA {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px; }

.styles_transactionTable__3EFCA td,
.styles_transactionTable__3EFCA th {
  border: 0;
  padding: 8px;
  text-align: left; }

/*
*
* TransactionHistory Styles
*
*/
/*
*
* SASS config
*
*/

.styles_tableContainer__1LZSX {
  width: 100%; }

.styles_startDate__2Ae3C {
  grid-area: startDate; }

.styles_endDate__2joF5 {
  grid-area: endDate; }

.styles_exportButton__2Rga- {
  grid-area: exportButton; }

.styles_dateFilterContainer__3SBrQ {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0; }
  @media (max-width: 767px) {
    .styles_dateFilterContainer__3SBrQ {
      flex-direction: column; } }
  .styles_dateFilterContainer__3SBrQ .styles_dateFilters__1tMwF {
    display: flex; }
    @media (max-width: 767px) {
      .styles_dateFilterContainer__3SBrQ .styles_dateFilters__1tMwF {
        flex-direction: column; } }
    .styles_dateFilterContainer__3SBrQ .styles_dateFilters__1tMwF .styles_startDate__2Ae3C,
    .styles_dateFilterContainer__3SBrQ .styles_dateFilters__1tMwF .styles_endDate__2joF5 {
      max-width: 200px;
      margin-right: 10px; }
      @media (max-width: 767px) {
        .styles_dateFilterContainer__3SBrQ .styles_dateFilters__1tMwF .styles_startDate__2Ae3C,
        .styles_dateFilterContainer__3SBrQ .styles_dateFilters__1tMwF .styles_endDate__2joF5 {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 10px; } }
  .styles_dateFilterContainer__3SBrQ .styles_exportButton__2Rga- {
    align-self: center; }
    @media (max-width: 767px) {
      .styles_dateFilterContainer__3SBrQ .styles_exportButton__2Rga- {
        margin: 10px 0; } }

/*
*
* ValueDetails Styles
*
*/
/*
*
* SASS config
*
*/

.styles_valueDetails__zymNW {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'activitySummary';
  grid-row-gap: 10px; }

.styles_activitySummary__FMwVJ {
  grid-area: activitySummary; }

.styles_detailTitle__2oQBv {
  color: rgba(0, 0, 0, 0.54); }

.styles_detailText__1CfLD {
  text-align: right; }

.styles_activitySummary__FMwVJ {
  width: 100%; }

.styles_summaryRow__3X65P {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-areas: 'detailTitle detailText';
  margin-bottom: 5px; }
  @media (max-width: 767px) {
    .styles_summaryRow__3X65P {
      font-size: small; } }

.styles_detailTitle__2oQBv {
  grid-area: detailTitle; }

.styles_detailText__1CfLD {
  grid-area: detailText; }

.styles_buttonIcons__f8NRS {
  grid-area: buttonIcons; }

.styles_detailTitle__2oQBv, .styles_detailText__1CfLD {
  align-self: center; }

/*
*
* OptionDetails Styles
*
*/
/*
*
* SASS config
*
*/

.styles_optionDetails__n5Pxo {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .styles_optionDetails__n5Pxo {
      flex-direction: column; } }

.styles_option__3jJKE {
  display: flex;
  justify-content: flex-start; }

.styles_optionName__3y6UK {
  width: 100%; }

.styles_remainingOptions__1IoKl {
  width: 80%;
  text-align: right; }
  @media (max-width: 767px) {
    .styles_remainingOptions__1IoKl {
      text-align: left; } }

.styles_optionDetailsContainer__3mDI7 table td,
.styles_optionDetailsContainer__3mDI7 table th {
  font-size: medium !important; }

/*
  *
  * Search Styles
  *
  */
/*
*
* SASS config
*
*/

.styles_search__uJ6FE {
  display: flex;
  flex-direction: column; }

.styles_searchMenu__2S58v {
  color: black;
  align-self: flex-end;
  position: absolute;
  background-color: #fff;
  z-index: 20;
  box-shadow: 0 2px 10px #c8c8c8;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 150ms;
  border-radius: 5px; }

.styles_searchMenu__2S58v > li:first-of-type {
  border-radius: 5px 5px 0 0; }

.styles_searchMenu__2S58v > li:last-of-type {
  border-radius: 0 0 5px 5px; }

.styles_searchMenu__2S58v.styles_show__3DsDr {
  transform: scaleY(1); }

.styles_searchOverlayShow__18ObN {
  display: inline;
  position: fixed;
  top: 0;
  left: 0; }

/*
*
* AccountsSummary Styles
*
*/
/*
*
* SASS config
*
*/

.styles_totalAmtAndAddAcctBtn__3G24G,
.styles_totalAmtAndAddAcctBtnTILE__1wb5o {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  width: 100%;
  align-items: center; }
  @media (max-width: 767px) {
    .styles_totalAmtAndAddAcctBtn__3G24G,
    .styles_totalAmtAndAddAcctBtnTILE__1wb5o {
      flex-direction: column;
      grid-row-gap: 15px;
      row-gap: 15px;
      text-align: center; } }
  @media (min-width: 1024px) {
    .styles_totalAmtAndAddAcctBtn__3G24G,
    .styles_totalAmtAndAddAcctBtnTILE__1wb5o {
      max-width: 1200px; } }

.styles_totalAmtAndAddAcctBtnTILE__1wb5o {
  max-width: 100%; }

.styles_totalAmtAndAddAcctBtn__3G24G .styles_label__26f-o,
.styles_totalAmtAndAddAcctBtnTILE__1wb5o .styles_label__26f-o {
  margin-top: 3px;
  text-transform: uppercase; }

.styles_accountsSummaryContainer__2pwPQ {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  text-transform: uppercase;
  width: 100%; }
  @media (min-width: 1024px) {
    .styles_accountsSummaryContainer__2pwPQ {
      max-width: 1200px; } }

.styles_accountsSummaryContainerTILE__1HqfE {
  display: grid;
  text-transform: uppercase;
  justify-content: center; }
  @media (min-width: 1024px) {
    .styles_accountsSummaryContainerTILE__1HqfE {
      grid-template-columns: repeat(2, minmax(0px, 50%));
      width: 100%;
      grid-gap: 10px;
      gap: 10px; } }
  @media (max-width: 1023px) {
    .styles_accountsSummaryContainerTILE__1HqfE {
      grid-template-columns: 100%;
      align-items: center; } }

.styles_beneficiaryAccountTypeContainer__1Z91e {
  width: 100%; }
  .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountType__1_zCy,
  .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountTypeInterestedParty__2btYi {
    background-color: #0072CF;
    color: #FFFFFF;
    font-weight: bold;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    text-transform: capitalize;
    letter-spacing: 3px; }
  .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountTypeInterestedParty__2btYi {
    background-color: #41AD49; }
  .styles_beneficiaryAccountTypeContainer__1Z91e .styles_closedAccount__1LS_Z {
    background-color: #fae500; }
  .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb {
    padding: 20px; }
    .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz {
      display: flex;
      justify-content: space-between; }
      .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz .styles_scholarshipEntity__1UOvX {
        display: flex;
        align-items: center; }
      .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz .styles_scholarshipEntity__1UOvX span {
        margin-right: 10px; }
      .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz .styles_scholarshipEntity__1UOvX p {
        font-size: small; }
      .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz .styles_totalContainer__1_AmK {
        display: flex;
        flex-direction: column;
        text-align: right; }
      .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz .styles_marketDateContainer__2chrc {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0px;
        color: rgba(0, 0, 0, 0.54); }
      .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz .styles_marketDateContainer__2chrc p:nth-of-type(1) {
        color: rgba(0, 0, 0, 0.54);
        font-size: small;
        margin: 0px 5px 0px 0px; }
      .styles_beneficiaryAccountTypeContainer__1Z91e .styles_accountGroupInfoContainer__oytcb .styles_beneNameAndTotalContainer__1KEQz .styles_marketDateContainer__2chrc p:nth-of-type(2) {
        font-size: small;
        font-weight: bold; }

.styles_accountRow__2JLpM {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_,
  .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 {
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-columns: 25% 55% 20%;
    grid-template-areas: 'accountNumberAndOption transactionLinks accountValueContainer'; }
    @media (max-width: 1023px) {
      .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_,
      .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 {
        grid-template-columns: 50% 50%;
        grid-template-areas: 'accountNumberAndOption accountValueContainer' 'transactionLinks transactionLinks'; } }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_accountNumberAndOptionContainer__2YOl-,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_accountNumberAndOptionContainer__2YOl- {
      grid-area: accountNumberAndOption;
      justify-self: flex-start;
      align-self: flex-start; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_accountValueContainer__NdFl-,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_accountValueContainer__NdFl- {
      grid-area: accountValueContainer; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_transactionLinks__2_xBh,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_transactionLinks__2_xBh {
      grid-area: transactionLinks; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_accountNumberAndOptionContainer__2YOl-,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_accountNumberAndOptionContainer__2YOl- {
      display: flex;
      flex-direction: column;
      font-size: x-large; }
      .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_accountNumberAndOptionContainer__2YOl- .styles_optionName__unvVJ,
      .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_accountNumberAndOptionContainer__2YOl- .styles_optionName__unvVJ {
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.54);
        font-weight: bold;
        font-size: small;
        margin-top: 0px; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_accountNumberAndOptionContainer__2YOl- *,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_accountNumberAndOptionContainer__2YOl- * {
      margin-top: 10px; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_transactionLinks__2_xBh,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_transactionLinks__2_xBh {
      display: flex; }
      @media (max-width: 1023px) {
        .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_transactionLinks__2_xBh,
        .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_transactionLinks__2_xBh {
          justify-self: center !important;
          align-self: center; } }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_transactionLinks__2_xBh button,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_transactionLinks__2_xBh button {
      color: #0072CF;
      text-decoration: none;
      font-weight: bold;
      font-size: small;
      margin-right: 3px;
      margin-left: 3px; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_accountValueContainer__NdFl-,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_accountValueContainer__NdFl- {
      display: flex;
      flex-direction: column;
      text-align: right; }
      .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_accountValueContainer__NdFl- .styles_totalValue__1nc_4,
      .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_accountValueContainer__NdFl- .styles_totalValue__1nc_4 {
        font-size: x-large; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_personalRateReturnContainer__2pvWF,
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_personalRateReturnContainer__2pvWF {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0px;
      color: rgba(0, 0, 0, 0.54); }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_personalRateReturnContainer__2pvWF p:nth-of-type(1),
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_personalRateReturnContainer__2pvWF p:nth-of-type(1) {
      color: rgba(0, 0, 0, 0.54);
      font-size: small;
      margin: 0px 5px 0px 0px; }
    .styles_accountRow__2JLpM .styles_accountInfoContainer__1NgS_ .styles_personalRateReturnContainer__2pvWF p:nth-of-type(2),
    .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 .styles_personalRateReturnContainer__2pvWF p:nth-of-type(2) {
      font-size: small;
      font-weight: bold; }
  .styles_accountRow__2JLpM .styles_accountInfoContainerTILE__e8ck3 {
    display: grid;
    align-items: flex-start;
    grid-template-columns: minmax(0px, 45%) minmax(0px, 55%);
    grid-template-areas: 'accountNumberAndOption accountValueContainer' 'transactionLinks transactionLinks'; }

.styles_accountIsClosed__1oRpb {
  background-color: #F5F5F4; }

.styles_accountBorder__2Z7gX {
  border: 1px solid #F5F5F4; }

.styles_label__26f-o {
  color: rgba(0, 0, 0, 0.54);
  font-size: small;
  margin-bottom: 3px; }

.styles_age__1SJlF {
  color: rgba(0, 0, 0, 0.54);
  font-size: small;
  margin: 0; }

.styles_data__3zktO {
  margin: 0px;
  font-size: xx-large; }
  @media (max-width: 767px) {
    .styles_data__3zktO {
      font-size: x-large; } }

.styles_negativeReturn__3HlXG {
  color: var(--error); }

.styles_positiveReturn__1LCwo {
  color: #41AD49; }

.styles_closedAccountBanner__1dHYy {
  color: rgba(0, 0, 0, 0.54);
  background-color: #F5F5F4;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_buttonAndAcctSummContainer__xYLSD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }
  @media (min-width: 1024px) {
    .styles_buttonAndAcctSummContainer__xYLSD {
      align-items: center; } }

.styles_filterAndSearch__3awog {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  @media (min-width: 1024px) {
    .styles_filterAndSearch__3awog {
      max-width: 1200px; } }

@media (max-width: 1023px) {
  .styles_filter__30HCE {
    align-self: flex-end;
    margin-right: 8px;
    width: 50px;
    height: 48px; } }

.styles_search__X3tW3 {
  margin-top: 5px;
  margin-right: 15px;
  align-self: center; }
  @media (max-width: 1023px) {
    .styles_search__X3tW3 {
      align-self: flex-end; } }

.styles_dateOpened__2Fbjh {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-size: small; }

/*
*
* Accounts Styles
*
*/
/*
*
* SASS config
*
*/

.styles_accountsContainer__afGvT, .styles_tabsContainer__3lRnx {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }

.styles_accountSelector__2wr59 {
  max-width: 70vw;
  margin-bottom: 25px; }

.styles_accountSelector__2wr59 a, .styles_accountSelector__2wr59 button {
  height: 65px; }

.styles_accountSelector__2wr59 button:hover {
  color: var(--primary); }

.styles_selected__3CLPh {
  font-weight: bold;
  font-size: 1.2em;
  color: #0072CF; }

.styles_accountLinks__1s_IK {
  color: rgba(0, 114, 207, 0.4); }

/*
*
* TransactionsHub Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__23yaW {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1300px;
  margin-top: 25px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-columns: 100%;
  grid-template-areas: 'transactionButtons' 'upcomingTransactionsCard' 'transactionsCard'; }

.styles_transactionButtons__3GOI7 {
  grid-area: transactionButtons; }

.styles_transactionsCard__2-fbc {
  grid-area: transactionsCard; }

.styles_upcomingTransactionsCard__3NcXM {
  grid-area: upcomingTransactionsCard; }

.styles_transactionButtons__3GOI7 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
  flex-wrap: nowrap; }
  @media (max-width: 767px) {
    .styles_transactionButtons__3GOI7 {
      flex-direction: column; } }

/*
*
* UpcomingSchedules Styles
*
*/
.styles_tableContainer__2N_yr {
  width: 100%;
  margin-top: 10px; }

.styles_Transaction_container__2fV5r {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  margin: 10px; }

.styles_transactionNavigation__2CvET {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px; }

.styles_transactionTitle__EHO6E {
  margin-top: 18px; }

.styles_transactionTableWrapper__18Ycl {
  text-align: center; }

.styles_transactionTable__1IShm {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px; }

.styles_transactionTable__1IShm td,
.styles_transactionTable__1IShm th {
  border: 0;
  padding: 8px;
  text-align: left; }

/*
*
* TransactionHistory Styles
*
*/
/*
*
* SASS config
*
*/

.styles_tableContainer__hHDcL {
  width: 100%; }

.styles_startDate__2LUjA {
  grid-area: startDate; }

.styles_endDate__3LED3 {
  grid-area: endDate; }

.styles_exportButton__KMTQe {
  grid-area: exportButton; }

.styles_dateFilterContainer__3QU2p {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0; }
  @media (max-width: 767px) {
    .styles_dateFilterContainer__3QU2p {
      flex-direction: column; } }
  .styles_dateFilterContainer__3QU2p .styles_dateFilters__1Kyf3 {
    display: flex; }
    @media (max-width: 767px) {
      .styles_dateFilterContainer__3QU2p .styles_dateFilters__1Kyf3 {
        flex-direction: column; } }
    .styles_dateFilterContainer__3QU2p .styles_dateFilters__1Kyf3 .styles_startDate__2LUjA,
    .styles_dateFilterContainer__3QU2p .styles_dateFilters__1Kyf3 .styles_endDate__3LED3 {
      max-width: 200px;
      margin-right: 10px; }
      @media (max-width: 767px) {
        .styles_dateFilterContainer__3QU2p .styles_dateFilters__1Kyf3 .styles_startDate__2LUjA,
        .styles_dateFilterContainer__3QU2p .styles_dateFilters__1Kyf3 .styles_endDate__3LED3 {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 10px; } }
  .styles_dateFilterContainer__3QU2p .styles_exportButton__KMTQe {
    align-self: center; }
    @media (max-width: 767px) {
      .styles_dateFilterContainer__3QU2p .styles_exportButton__KMTQe {
        margin: 10px 0; } }

/*
*
* AccountListDialog Styles
*
*/
/*
*
* SASS config
*
*/

.styles_subTitle__3dtua {
  margin-left: 0.5em;
  font-weight: bold; }

.styles_list__sxetS {
  min-width: 200px; }

.styles_threeItemRow__1uA_Y {
  display: flex;
  flex-wrap: wrap; }

.styles_threeItemCell__1RZX4 {
  flex: 0 0 33.333333%; }

.styles_cellValues__2sbc3 {
  color: rgba(0, 0, 0, 0.54); }

/*
*
* TransactionButtons Styles
*
*/
/*
*
* SASS config
*
*/

.styles_transactionButton__gnptE {
  width: 100%; }

.styles_transactionButton__gnptE:not(:first-child) {
  padding-left: 0.5em; }
  @media (max-width: 767px) {
    .styles_transactionButton__gnptE:not(:first-child) {
      padding-left: 0; } }

.styles_transactionButton__gnptE:not(:last-child) {
  padding-bottom: 0; }
  @media (max-width: 767px) {
    .styles_transactionButton__gnptE:not(:last-child) {
      padding-bottom: 0.5em; } }

/*
*
* BalanceGraph Styles
*
*/
/*
*
* SASS config
*
*/

.styles_valueGraphContainer__2ksZM {
  width: 100%;
  height: 350px; }

.styles_fullscreenGraphContainer__2C7nD {
  width: 100%;
  height: 100%; }

.styles_tooltip__tV7r4 {
  font-size: x-small;
  padding: 10px;
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 99; }

.styles_tooltipLegend__UpZ_z {
  grid-area: legend; }

.styles_tooltipName__3lg2z {
  grid-area: name; }

.styles_tooltipBalance___TmM2 {
  grid-area: balance; }

.styles_tooltipLine__298l8 {
  min-width: 180px;
  margin: 3px 0;
  display: grid;
  font-size: x-small;
  grid-template-columns: 30px 70px 80px;
  grid-template-areas: 'legend name balance';
  align-items: center; }

.styles_tooltipLine__298l8:first-of-type {
  margin-top: 10px; }

.styles_tooltipLine__298l8:last-child {
  font-weight: bold; }

.styles_tooltipName__3lg2z {
  padding: 0 3px; }

.styles_tooltipBalance___TmM2 {
  font-weight: bold;
  text-align: right; }

.styles_xAxis__36ecW path,
.styles_xAxis__36ecW g line,
.styles_yAxis__19YTH path,
.styles_yAxis__19YTH g line {
  visibility: hidden; }

/*
*
* ValueByDate Styles
*
*/
/*
*
* SASS config
*
*/

.styles_filterAndSearch__1Wp-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5px; }

@media (min-width: 1400px) {
  .styles_filterAndSearch__1Wp-f {
    flex-direction: row;
    padding-bottom: 0; } }

.styles_filter__3ICRZ {
  align-self: flex-end;
  margin-right: 18px;
  width: 50px;
  height: 48px; }

.styles_search__2g82W {
  margin-right: 15px;
  align-self: flex-end; }

.styles_balanceByDateContainer__28kLj {
  height: 350px;
  overflow-y: auto;
  background: linear-gradient(#F5F5F5 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #F5F5F5 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll; }

.styles_accountValueContainer__2jRCk {
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 20px 0 10px;
  margin: 10px 0; }

.styles_hasNoBalance__rKgay {
  filter: opacity(0.5); }

.styles_beneName__2gaNs,
.styles_optionName__3TXj2 {
  margin-bottom: 3px; }

.styles_optionName__3TXj2 {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-size: x-small; }

.styles_accountDivider__1HUpf {
  margin: 0 20px 0 10px; }

.styles_dateFilter__2Myz9 {
  margin: 20px 20px 0 0; }
  @media (max-width: 767px) {
    .styles_dateFilter__2Myz9 {
      margin-left: 0; } }

.styles_image__1bUT9 {
  text-align: center;
  padding: 0.7em; }

/*
*
* Overview Styles
*
*/
/*
*
* SASS config
*
*/

.styles_balanceOverTimeContainer__3IgwI {
  grid-area: balanceOverTimeContainer; }

.styles_balanceByDateSection__1GwJD {
  grid-area: balanceByDateContainer; }

.styles_overviewContainer__3F3Rc {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-areas: 'balanceOverTimeContainer balanceByDateContainer'; }
  @media (max-width: 767px) {
    .styles_overviewContainer__3F3Rc {
      grid-template-columns: 100%;
      grid-template-areas: 'balanceOverTimeContainer' 'balanceByDateContainer'; } }

.styles_balanceOverTimeSection__PrNWP {
  margin: auto 0;
  margin-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.12); }
  @media (max-width: 767px) {
    .styles_balanceOverTimeSection__PrNWP {
      margin-right: 0;
      padding-bottom: 10px;
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); } }

.styles_balanceOverTimeFullscreen__AfmnH {
  padding: 20px;
  min-width: 600px;
  width: 100%;
  height: 100%; }

.styles_graphDateFilterContainer__ah67Z {
  display: flex;
  min-height: 66px;
  width: 100%;
  padding-left: 10px;
  align-items: center; }
  @media (max-width: 1023px) {
    .styles_graphDateFilterContainer__ah67Z {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 0; } }

.styles_dateFilterChips__3Llop {
  display: flex-root;
  align-items: center; }
  @media (max-width: 1023px) {
    .styles_dateFilterChips__3Llop {
      min-height: 66px;
      padding-top: 15px; } }

.styles_datePickers__65DPX {
  display: flex;
  justify-content: center; }
  @media (max-width: 1023px) {
    .styles_datePickers__65DPX {
      margin: auto; } }
  @media (max-width: 767px) {
    .styles_datePickers__65DPX {
      flex-direction: column;
      margin: 0;
      width: 100%; } }

.styles_startDate__1zPEU,
.styles_endDate__2d1lV {
  width: 180px;
  margin-left: 10px; }
  @media (max-width: 767px) {
    .styles_startDate__1zPEU,
    .styles_endDate__2d1lV {
      width: 100%;
      margin: 5px 0; } }

.styles_aboveGraphContainer__2CiJy {
  display: flex;
  justify-content: space-between; }

.styles_dropdownContainer__jZCNI {
  display: flex;
  align-items: center;
  width: 100%; }
  @media (max-width: 767px) {
    .styles_dropdownContainer__jZCNI {
      flex-direction: column; } }

.styles_accountDropdown__34osn {
  width: 100%;
  max-width: 400px;
  height: 90px; }

.styles_savingsCalulatorButton__2jhAK {
  margin-left: 20px; }
  @media (max-width: 767px) {
    .styles_savingsCalulatorButton__2jhAK {
      margin: 15px 0; } }

.styles_toolTipContainer__2n3ZP {
  height: 100%; }

.styles_balanceGraphContainer__1Kkm- {
  width: 100%;
  height: 350px;
  margin: 20px 0; }
  @media (max-width: 767px) {
    .styles_balanceGraphContainer__1Kkm- {
      border-right: 1px solid rgba(0, 0, 0, 0.12); } }

.styles_balanceGraphFullscreenContainer__24f9C {
  width: 100%;
  height: 55vh;
  margin-bottom: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.styles_balanceByDateSection__1GwJD {
  margin-right: -15px;
  margin-top: -5px; }

.styles_warningDisplayContainer__31MAK {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767px) {
    .styles_warningDisplayContainer__31MAK {
      height: 100%; } }

.styles_butterfly__3Qqxl {
  width: 400px;
  margin-left: 20%; }
  @media (max-width: 767px) {
    .styles_butterfly__3Qqxl {
      width: 250px; } }

.styles_warningHeader__1M_lV {
  font-size: xxx-large;
  font-weight: bold; }
  @media (max-width: 767px) {
    .styles_warningHeader__1M_lV {
      font-size: xx-large; } }

@media (max-width: 767px) {
  .styles_warningText__Vjcce {
    font-size: small; } }

/*
*
* AccessCardBanner Styles
*
*/
/*
*
* SASS config
*
*/

.styles_bannerContainer__3ryqu {
  text-align: center; }

.styles_imageContainer__2s0os {
  width: 90%;
  max-width: 1000px;
  margin: 10px auto;
  position: relative; }
  @media (max-width: 1023px) {
    .styles_imageContainer__2s0os {
      width: 80%; } }

.styles_bannerImage__2nRkd {
  width: 100%;
  cursor: pointer; }

.styles_closeButton__1yspb {
  position: absolute;
  top: 2px;
  right: 0; }

/*
*
* SASS config
*
*/

.styles_container__1nyJs {
  width: 100%;
  background-color: #EA7125;
  margin: 1em 0;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white; }

.styles_paperlessContainer__D5uYS {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .styles_paperlessContainer__D5uYS {
      flex-direction: column; } }

@media (max-width: 767px) {
  .styles_buttonSettings__Scqmg {
    margin-top: 0.5em; } }

.styles_paperlessIcon__2ka6h {
  margin-left: 0.3em; }

.styles_paperless__2u181 {
  display: flex;
  align-items: center; }

.styles_paperlessLabel__131rl {
  margin: 0 1em;
  font-size: large; }

.styles_paperlessText__qDm-R {
  margin: 0 1em; }

/*
*
* Home Styles
*
*/
/*
*
* SASS config
*
*/

.styles_detailTitle__2CjVc {
  font-weight: bold; }

.styles_detailText__3McwG {
  text-align: right; }

.styles_homeContainer__23B5E {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_overviewHeading__3ur1g,
.styles_accountsHeading__5twEa {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: #6B6E6F;
  margin: 0px; }
  @media (max-width: 1023px) {
    .styles_overviewHeading__3ur1g,
    .styles_accountsHeading__5twEa {
      flex-direction: column;
      flex-wrap: wrap; } }

.styles_titleAndFilter__1hlyn {
  display: flex;
  align-items: center; }

@media (max-width: 1023px) {
  .styles_filter__2qtoM {
    align-self: flex-end;
    margin-right: 8px;
    width: 50px;
    height: 48px; } }

.styles_search__2ambp {
  margin-top: 5px;
  margin-right: 15px;
  align-self: center; }
  @media (max-width: 1023px) {
    .styles_search__2ambp {
      align-self: flex-end; } }

@media (max-width: 1023px) {
  .styles_overviewHeading__3ur1g :nth-child(2) {
    order: -1; } }

.styles_overviewHeading__3ur1g {
  font-weight: bold; }

.styles_ownerInfo__37on9 {
  display: flex;
  align-items: center;
  margin: 0px; }

.styles_accountsHeadingContainer__17bkr {
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 0 16px;
  margin: auto; }

.styles_overviewHeading__3ur1g h1,
.styles_accountsHeading__5twEa h1 {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  margin-right: 8px;
  opacity: 0.89;
  display: flex;
  align-items: center;
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .styles_overviewHeading__3ur1g h1,
    .styles_accountsHeading__5twEa h1 {
      font-size: small; } }

.styles_overviewHeading__3ur1g div,
.styles_accountsHeading__5twEa p {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  opacity: 0.89; }
  @media (max-width: 767px) {
    .styles_overviewHeading__3ur1g div,
    .styles_accountsHeading__5twEa p {
      font-size: small; } }

.styles_accountsSummaryContainer__AqD_g {
  background-color: #F5F5F5;
  display: flex;
  justify-content: center; }
  @media (max-width: 1023px) {
    .styles_accountsSummaryContainer__AqD_g {
      width: 100%; } }
  @media (max-width: 767px) {
    .styles_accountsSummaryContainer__AqD_g {
      padding: 0px !important; } }

.styles_viewIcon__1EnaR {
  display: flex;
  align-items: center;
  visibility: hidden; }
  @media (min-width: 1024px) {
    .styles_viewIcon__1EnaR {
      visibility: visible; } }

.styles_transactionsAccordion__wy-Ig {
  display: inline;
  margin: auto; }

/*
*
* SASS config
*
*/

.styles_learnMoreInfoContainer__2uF6L {
  padding: 20px;
  padding-top: 10px; }

.styles_divider__2u5nE {
  border: 1px solid #F5F5F4; }

.styles_closeIcon__SdO4T {
  text-align: right; }

.styles_learnMoreInfo__2uB93 {
  font-size: small;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 25px; }

.styles_learnMoreInfoHeader__2zGcF {
  text-align: center;
  margin: 10px 0px; }

/*
*
* BeneTable Styles
*
*/
/*
*
* SASS config
*
*/

.styles_lpoaHeader__1QMs0 {
  margin-right: 10px;
  margin-bottom: 20px;
  justify-content: flex-end; }

.styles_button__3_Os1 {
  margin-right: 10px;
  margin-top: auto; }

.styles_actionHeader__1T5Uw {
  display: grid;
  grid-template-areas: 'actionButtons addEditHelpText'; }
  @media (max-width: 767px) {
    .styles_actionHeader__1T5Uw {
      grid-template-areas: 'addEditHelpText' 'actionButtons'; } }

.styles_addEditHelpText__ef7v6 {
  grid-area: addEditHelpText; }

.styles_actionButtons__3NbNT {
  grid-area: actionButtons; }

.styles_actionButtons__3NbNT, .styles_lpoaHeader__1QMs0 {
  display: flex; }

.styles_actionButtons__3NbNT {
  margin-left: 20px;
  margin-bottom: 20px;
  justify-content: left; }

.styles_addEditHelpText__ef7v6 {
  padding: 0.5em;
  font-size: small; }

.styles_lpoaHeaderName__2jG3_ {
  margin-right: 30px;
  margin-top: auto; }

.styles_borderTop__1AsBt {
  border-top: 1px solid rgba(0, 0, 0, 0.3); }

.styles_lpoaList__2Xg4_ {
  width: 80%; }
  @media (max-width: 767px) {
    .styles_lpoaList__2Xg4_ {
      width: 100%; } }

.styles_paperContainer__2EyHn {
  margin: 0 auto; }

.styles_paperTitle__1JxYn {
  background-color: #0072CF;
  color: #FFFFFF;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px 4px 0px 0px;
  letter-spacing: 3px; }

.styles_dashRow__18wei {
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.7fr 0.5fr 0.5fr;
  grid-template-areas: "checkbox beneficiary poaLevel fedTaxDocs stateTaxDocs";
  background: #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 20px;
  margin-right: 20px; }
  @media (max-width: 767px) {
    .styles_dashRow__18wei {
      grid-template-columns: 0.3fr 0.5fr 0.5fr 0.5fr;
      grid-template-areas: "checkbox beneficiary beneficiary beneficiary" "checkbox poaLevel fedTaxDocs stateTaxDocs"; } }

.styles_checkbox__2-Gki {
  grid-area: checkbox;
  align-self: center; }

.styles_beneficiary__1RddG {
  grid-area: beneficiary; }

.styles_poaLevel__3IfR7 {
  grid-area: poaLevel; }

.styles_fedTaxDocs__4Pemj {
  grid-area: fedTaxDocs; }

.styles_stateTaxDocs__XiEbS {
  grid-area: stateTaxDocs; }

.styles_checkbox__2-Gki, .styles_beneficiary__1RddG, .styles_poaLevel__3IfR7, .styles_fedTaxDocs__4Pemj, .styles_stateTaxDocs__XiEbS {
  margin: 8px 0px; }

.styles_label__71hJb,
.styles_labelTILE__2PLJn {
  color: rgba(0, 0, 0, 0.3);
  font-size: small;
  text-transform: uppercase; }

@media (max-width: 1023px) {
  .styles_labelTILE__2PLJn {
    font-size: x-small; } }

.styles_data__3fWMT,
.styles_dataTILE__3GAp5 {
  margin: 0px;
  font-size: x-large;
  text-transform: uppercase; }

@media (max-width: 1023px) {
  .styles_dataTILE__3GAp5 {
    font-size: large; } }

/*
*
* LpoaStepper Styles
*
*/
.styles_stepperContentContainer__1r7Dn {
  display: flex;
  flex-direction: column;
  text-align: center; }

.styles_stepperContent__2wqcX {
  margin-bottom: 20px;
  width: 100%; }

.styles_stepperSteps__2rtXj {
  width: 30%;
  margin: auto; }

.styles_stepper__oSLPe {
  text-align: center; }

@media print {
  .styles_stepperSteps__2rtXj,
  .styles_stepperButtons__2yuVd {
    display: none !important; } }

/*
*
* EditLpoa Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__xesgl {
  width: 80%;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px; }
  @media (max-width: 767px) {
    .styles_container__xesgl {
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      padding: 5px; } }

.styles_levelSelectionContainer__1NUhH {
  padding: 20px; }

.styles_levelSelectionContainer__1NUhH h2,
.styles_review__2eFRv h2,
.styles_review__2eFRv h3 {
  text-transform: uppercase;
  text-align: center; }

.styles_levelSelectionContainer__1NUhH table {
  border-collapse: collapse;
  width: '100%'; }

.styles_levelSelectionContainer__1NUhH table td {
  border: 1px solid lightgray;
  padding: 10px;
  text-align: center; }

.styles_levelSelectionContainer__1NUhH table thead,
.styles_levelSelectionContainer__1NUhH table tbody tr:nth-child(even) {
  background-color: #f2f8ff; }

.styles_levelSelectionContainer__1NUhH table tr:first-child td {
  border-top: 0; }

.styles_levelSelectionContainer__1NUhH table tr td:first-child {
  border-left: 0; }

.styles_levelSelectionContainer__1NUhH table tr:last-child td {
  border-bottom: 0; }

.styles_levelSelectionContainer__1NUhH table tr td:last-child {
  border-right: 0; }

.styles_taxSelectionSeparator__1yigW {
  color: lightgray; }

.styles_formsTitle__14ycB {
  color: white;
  background-color: #0072CF;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  text-align: left; }

.styles_formsRow__7x5uZ {
  display: flex;
  padding: 15px; }

.styles_formSelection__3UL4P {
  width: 15%;
  min-width: 100px; }

.styles_formSelectionLabel__2-pZj {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left; }

.styles_review__2eFRv {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left; }

.styles_agreeToTerms__3eTHO {
  text-align: center; }

@media (min-width: 1024px) {
  .styles_printButton__sUju5 {
    position: absolute;
    top: 80px;
    right: 25px; } }

@media (max-width: 1023px) {
  .styles_printButton__sUju5 {
    text-align: center; } }

/*
*
* Lpoa Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__1Vcwd {
  display: flex;
  justify-content: center; }

.styles_authCodeEntryComponent__3VGuF {
  width: 400px;
  padding: 5px 30px; }
  @media (max-width: 767px) {
    .styles_authCodeEntryComponent__3VGuF {
      width: 100%; } }

.styles_codeInput__fme2A, .styles_poaName__19WGP, .styles_navButtons__12rWx {
  display: flex; }

.styles_codeInput__fme2A, .styles_poaName__19WGP, .styles_navButtons__12rWx {
  margin-top: 20px;
  justify-content: center; }

.styles_button__utVGD {
  margin-right: 10px;
  margin-top: auto; }

.styles_learnMore__3perB {
  text-align: center;
  padding: 20px; }

/*
*
* SASS config
*
*/

.styles_learnMoreInfoContainer__3nZx8 {
  padding: 20px;
  padding-top: 10px; }

.styles_divider__2hm_L {
  border: 1px solid #F5F5F4; }

.styles_closeIcon__dyrVi {
  text-align: right; }

.styles_learnMoreInfo__1ZCAo {
  font-size: small;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 25px; }

.styles_learnMoreInfoHeader__3wB-B {
  text-align: center;
  margin: 10px 0px; }

/*
*
* SASS config
*
*/

.styles_paperContainer__ASfEn {
  width: 80%;
  margin: 0 auto; }

.styles_paperTitle__191_E {
  background-color: #0072CF;
  color: #FFFFFF;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px 4px 0px 0px;
  letter-spacing: 3px;
  text-transform: capitalize; }

.styles_dashRow__FOCrM {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr .5fr .5fr .5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "beneficiary code acct status hideName manage";
  grid-column-gap: 10px;
  background: #FFF; }
  @media (max-width: 767px) {
    .styles_dashRow__FOCrM {
      display: flex;
      flex-direction: column; } }

.styles_beneficiary__1NYtT {
  grid-area: beneficiary; }

.styles_code__2A85n {
  grid-area: code; }

.styles_acct__A4GEp {
  grid-area: acct; }

.styles_status__1n8L1 {
  grid-area: status;
  text-align: center; }

.styles_hideName__3KuDQ {
  grid-area: hideName;
  text-align: center; }

.styles_manage__1VNMf {
  grid-area: manage;
  font-size: 11px;
  text-align: center;
  align-self: center;
  text-transform: uppercase; }

.styles_manage__1VNMf:hover {
  cursor: pointer; }

.styles_label__3yiBd,
.styles_labelTILE__35hD8 {
  color: rgba(0, 0, 0, 0.3);
  font-size: small;
  margin-bottom: 3px;
  text-transform: uppercase; }

@media (max-width: 1023px) {
  .styles_labelTILE__35hD8 {
    font-size: x-small; } }

.styles_data__3LdUX,
.styles_dataTILE__3et1y {
  margin: 0px;
  font-size: x-large;
  text-transform: uppercase; }

@media (max-width: 1023px) {
  .styles_dataTILE__3et1y {
    font-size: large; } }

.styles_acctType__3F9Jv {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 12px;
  color: #444;
  text-transform: capitalize;
  font-weight: bold; }

.styles_learnMore__etjaJ {
  text-align: center;
  padding: 20px; }

/*
*
* SASS config
*
*/

.styles_manageGifting__3Phr7 {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
  grid-template-areas: ". . buttons ." ". picture customText ." ". beneficiary url ." ". ao pageviewscode ." ". acct social ." ". history history .";
  grid-gap: 20px; }
  @media (max-width: 767px) {
    .styles_manageGifting__3Phr7 {
      display: flex;
      flex-direction: column; } }

.styles_tableTitle__3nVFd {
  text-align: center;
  margin-top: 25px;
  text-transform: uppercase;
  font-weight: bold; }

.styles_buttons__3a_o2 {
  grid-area: buttons;
  text-align: right; }

.styles_picture__3pxki {
  grid-area: picture; }

.styles_customText__3_vSa {
  grid-area: customText;
  align-self: center;
  width: 100%; }

.styles_beneficiary__YLlG- {
  grid-area: beneficiary; }

.styles_url__2eukm {
  grid-area: url; }

.styles_ao__sbi2I {
  grid-area: ao; }

.styles_pageviewscode__2fDNq {
  grid-area: pageviewscode; }

.styles_acct__a4SWi {
  grid-area: acct; }

.styles_social__2SoLu {
  grid-area: social; }

.styles_history__2yhcR {
  grid-area: history;
  padding-bottom: 30px; }

.styles_label__2YpQN,
.styles_labelTILE__VNCmI {
  color: rgba(0, 0, 0, 0.3);
  font-size: small;
  margin-bottom: 3px;
  text-transform: uppercase; }

@media (max-width: 1023px) {
  .styles_labelTILE__VNCmI {
    font-size: x-small; } }

.styles_data__2KsbV,
.styles_dataTILE__1m89t {
  margin: 0;
  font-size: x-large;
  text-transform: uppercase; }

@media (max-width: 1023px) {
  .styles_dataTILE__1m89t {
    font-size: large; } }

.styles_acctType__DWjf2 {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 12px;
  color: #444;
  text-transform: capitalize;
  font-weight: bold; }

.styles_messageLength__3z1NN {
  text-align: right;
  color: #3399FF;
  font-size: 10px;
  margin-top: 2px; }

.styles_giftingLink__NuhSW {
  cursor: pointer;
  font-size: 15px;
  border: 0; }

.styles_removeButton__3zPWn {
  margin-top: 10px; }

.styles_acctSettings__mT-Ky {
  display: grid;
  grid-template-areas: "hideLastName . .";
  grid-template-columns: 1fr 1fr 1fr; }

.styles_hideLastName__2z9n5 {
  align-content: center;
  align-items: center;
  display: grid;
  grid-area: hideLastName;
  grid-template-areas: "hideLastNameLabel hideLastNameInfo" "hideLastNameSwitch ." "hideLastNameStatus .";
  grid-template-columns: 1fr 0.25fr;
  grid-template-rows: 25px 50px 25px;
  justify-items: center; }

.styles_hideLastNameInfo__2hGKp {
  grid-area: hideLastNameInfo; }

.styles_hideLastNameLabel__3EohO {
  grid-area: hideLastNameLabel; }

.styles_hideLastNameStatus__1nC6Y {
  font-size: 12px;
  grid-area: hideLastNameStatus; }

.styles_hideLastNameSwitch__A9UJX {
  grid-area: hideLastNameSwitch; }

/*
*
* PersistentDrawer Styles
*
*/
/*
*
* SASS config
*
*/

.styles_row__38Yr5 {
  display: flex; }

.styles_forMobile__4wBfc {
  display: flex; }
  @media (max-width: 767px) {
    .styles_forMobile__4wBfc {
      flex-direction: column; } }

.styles_row__38Yr5 .styles_col__H1kyB,
.styles_row__38Yr5 .styles_forMobile__4wBfc {
  flex: 1 1; }

.styles_row__38Yr5 .styles_title__3Qh1E {
  max-width: 200px; }

.styles_row__38Yr5 .styles_arrow__2nIKn {
  max-width: 30px; }

.styles_end__2zqSV {
  text-align: right; }

.styles_header__3yRQj {
  display: flex;
  flex-direction: row; }

.styles_drawerHeaderItem__2mZn2 {
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 15px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto; }

.styles_closeButton__m9Grd {
  text-align: right; }

.styles_closeButtonArrow__3VEP6 {
  text-align: left;
  margin-right: auto; }

.styles_drawerContent__30jWP {
  margin: 15px; }
  @media (max-width: 767px) {
    .styles_drawerContent__30jWP {
      margin: 5px; } }

/*
*
* AccountDetails Styles
*
*/
/*
*
* SASS config
*
*/

.styles_readOnlyAddress__1fC_E {
  padding-top: 20px;
  width: 100%;
  text-align: center; }

.styles_titleContainer__2GGLj {
  display: flex;
  align-items: center;
  align-self: flex-start; }

.styles_titleContainer__2GGLj span {
  font-size: 18px;
  margin-left: 5px; }

.styles_tabs__3VNK4 {
  margin-bottom: 10px; }

/*
*
* EmailCard Styles
*
*/
.styles_emailAddress__36jdd {
  text-align: center;
  font-weight: bold; }

.styles_titleContainer__2FEtB {
  display: flex;
  align-items: center; }

.styles_titleContainer__2FEtB span {
  font-size: 18px;
  margin-left: 5px; }

/*
*
* MyInfo Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__3PZoH {
  margin: 15px; }
  @media (max-width: 767px) {
    .styles_container__3PZoH {
      margin: 5px; } }

.styles_title__3tOey {
  font-weight: bold;
  margin-right: 5px; }

.styles_titleWithInfoIcon__17lW8 {
  display: flex;
  align-items: center;
  align-self: flex-start; }

.styles_contentLighter__3AxMg {
  color: rgba(0, 0, 0, 0.54);
  align-self: end; }

.styles_row__2f8k5 {
  display: flex; }

.styles_row__2f8k5 .styles_col__3JlzF {
  flex: 1 1; }

.styles_aoName__iXdFk h2 {
  margin-top: 0; }

.styles_logo__1Qyu4 {
  text-align: right; }

.styles_passwordRequirements__vv68s {
  margin-top: 20px; }

/* ADDRESS */
.styles_addressType__Hl5E4 {
  grid-area: addressType; }

.styles_addressVerLine__NoIQq {
  grid-area: addressVerLine; }

.styles_addressStreet1__M5DNG {
  grid-area: addressStreet1; }

.styles_addressStreet2__2w6Mv {
  grid-area: addressStreet2; }

.styles_addressStreet3__1cTgx {
  grid-area: addressStreet3; }

.styles_addressCity__11kyT {
  grid-area: addressCity; }

.styles_addressStatePostal__1V0OY {
  grid-area: addressStatePostal; }

.styles_addressCountry__2Hg8F {
  grid-area: addressCountry; }

.styles_addressContainer__105s5 {
  display: flex;
  flex-wrap: wrap; }
  .styles_addressContainer__105s5 .styles_addressType__Hl5E4, .styles_addressContainer__105s5 .styles_addressVerLine__NoIQq, .styles_addressContainer__105s5 .styles_addressComma__1ojZE {
    margin-right: 5px; }
  @media (max-width: 767px) {
    .styles_addressContainer__105s5 {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas: 'addressType .' 'addressStreet1 .' 'addressStreet2 .' 'addressStreet3 .' 'addressCity .' 'addressStatePostal .' 'addressCountry .'; }
      .styles_addressContainer__105s5 .styles_addressVerLine__NoIQq,
      .styles_addressContainer__105s5 .styles_addressComma__1ojZE {
        display: none; }
      .styles_addressContainer__105s5 .styles_addressStatePostal__1V0OY {
        margin-bottom: 5px; } }

/* PHONE */
.styles_phoneType__1EOcK {
  grid-area: phoneType; }

.styles_phoneVerLine__2zTRY {
  grid-area: phoneVerLine; }

.styles_phoneNum__3dYnu {
  grid-area: phoneNum;
  white-space: 'nowrap'; }

.styles_phoneContainer__3FWHv {
  display: flex; }
  .styles_phoneContainer__3FWHv .styles_phoneType__1EOcK, .styles_phoneContainer__3FWHv .styles_phoneVerLine__2zTRY, .styles_phoneContainer__3FWHv .styles_phoneNum__3dYnu {
    margin-right: 5px; }
  @media (max-width: 767px) {
    .styles_phoneContainer__3FWHv {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas: 'phoneType .' 'phoneNum .'; }
      .styles_phoneContainer__3FWHv .styles_phoneVerLine__2zTRY {
        display: none; }
      .styles_phoneContainer__3FWHv .styles_phoneNum__3dYnu {
        margin-bottom: 5px; } }

/* little hack to prevent tab buttons to expand with screen size change */
.styles_address___9WOm button {
  min-width: 72px; }

/*
*
* StepComponents Styles
*
*/
/*
*
* SASS config
*
*/

.styles_warning__34WEK {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
  text-align: center;
  margin-bottom: 15px; }

/*
*
* Preferences Styles
*
*/
/*
*
* SASS config
*
*/

.styles_preferencesContainer__1IeYC table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll; }

.styles_preferencesContainer__1IeYC td,
.styles_preferencesContainer__1IeYC th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px; }

.styles_preferencesContainer__1IeYC tr:nth-child(even) {
  background-color: #eeeeee; }

/*
*
* SASS config
*
*/

.styles_container__3m1zm {
  text-align: center;
  padding: 15px; }
  @media (max-width: 1023px) {
    .styles_container__3m1zm {
      width: 90%; } }
  @media (max-width: 767px) {
    .styles_container__3m1zm {
      padding: 10px;
      width: 100%; } }

.styles_attemptWarning__1BV_4 {
  color: #E50000;
  margin: auto;
  margin-top: 10px;
  width: 60%; }
  @media (max-width: 767px) {
    .styles_attemptWarning__1BV_4 {
      width: 95%; } }

/*
*
* SASS config
*
*/

.styles_container__3Y12j {
  display: grid;
  grid-template-columns: .25fr 1fr;
  grid-gap: 15px 5px;
  gap: 15px 5px;
  width: 90%;
  font-weight: bold;
  margin: auto;
  grid-template-areas: "step1 step1text" "step2 step2text" "qrcode qrcode" "step3 step3text" "my529token my529token"; }

.styles_step1__3dvcz {
  grid-area: step1;
  text-align: right; }

.styles_step1text__2bsnb {
  grid-area: step1text;
  text-align: left; }

.styles_step2__18cgO {
  grid-area: step2;
  text-align: right; }

.styles_step2text__2w9OD {
  grid-area: step2text;
  text-align: left; }

.styles_qrcode__2U9OL {
  grid-area: qrcode; }

.styles_step3__1lrnj {
  grid-area: step3;
  text-align: right; }

.styles_step3text__3iRKW {
  grid-area: step3text;
  text-align: left; }

.styles_my529token__3tKBk {
  grid-area: my529token; }

.styles_attemptWarning__3mH5b {
  color: #E50000;
  margin: auto;
  margin-top: 10px;
  width: 60%; }
  @media (max-width: 767px) {
    .styles_attemptWarning__3mH5b {
      width: 95%; } }

/*
*
* SASS config
*
*/

.styles_container__1scbk {
  display: grid;
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: 5px;
  grid-template-columns: .25fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "label text";
  font-weight: bold;
  padding: 20px; }
  @media (max-width: 1023px) {
    .styles_container__1scbk {
      width: 90%; } }
  @media (max-width: 767px) {
    .styles_container__1scbk {
      padding: 10px;
      width: 100%;
      grid-gap: 3px; } }

.styles_label__3d7zs {
  grid-area: label;
  text-align: right; }

.styles_text__2PNvW {
  grid-area: text;
  text-align: left; }

.styles_options__sbFdd {
  width: 50%;
  margin: auto;
  padding: 15px; }
  @media (max-width: 767px) {
    .styles_options__sbFdd {
      padding: 10px;
      width: 80%;
      grid-gap: 3px; } }

.styles_auth_container__3gGtR {
  grid-template-rows: .5fr .5fr;
  grid-template-areas: "authenticator"; }

.styles_authenticator__jSWZb {
  grid-area: authenticator;
  text-align: center;
  display: flex;
  align-items: center; }

.styles_container__1uaeO {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr .5fr 1fr 1fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas: "phone phone" "login toggle" "OtherOptions OtherOptions" "authenticator authenticator"; }

.styles_phone__33giz {
  grid-area: phone; }

.styles_login__1SSgH {
  font-weight: bold;
  grid-area: login;
  align-self: center;
  grid-auto-rows: 1vw; }

.styles_toggle__2UpvX {
  grid-area: toggle;
  text-align: right;
  align-self: center; }

.styles_OtherOptions__2l_XY {
  grid-area: OtherOptions; }

.styles_authenticator__2Mh4C {
  grid-area: authenticator;
  text-align: center; }

/*
*
* SASS config
*
*/

.styles_container__2ZZft {
  margin: 30px; }

.styles_row__1CbDC {
  display: flex; }

.styles_row__1CbDC .styles_col__uSZza {
  flex: 1 1; }

.styles_row__1CbDC .styles_label__3g-wU {
  font-weight: bold;
  max-width: 260px;
  margin-top: auto;
  margin-bottom: auto; }

.styles_row__1CbDC .styles_toggle__3H1Us {
  max-width: 50px; }

.styles_header__2kXas {
  font-weight: bold;
  align-self: center; }

.styles_header_description__3kc4j {
  font-size: smaller;
  align-self: center;
  text-indent: 5px; }

.styles_dropDownStyle__3jAPQ {
  width: 250px;
  text-indent: 15px;
  padding-top: 10px; }

.styles_eachDropDown__2qSeX {
  padding-top: 20px; }

.styles_divider__3R4A_ {
  border: 1px solid #CCC; }

/*
*
* OptionChangeDetails Styles
*
*/
.styles_optionDetails__2rFV9 {
  height: 100%; }

.styles_zeroOptionChangesRemaining__UfwSm {
  color: var(--error);
  font-weight: bold; }

.styles_detailsTitle__20yWU {
  font-weight: bold;
  margin: 5px 0;
  text-align: center; }

/*
*
* OptionChanges Styles
*
*/
.styles_newOptions__3IjiB {
  height: 100%; }

.styles_termsAndConditions__2dp1j {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 850px;
  margin-top: 20px; }

.styles_termsAndConditions__2dp1j > h5 {
  margin-bottom: 0;
  text-align: center; }

.styles_termsAndConditions__2dp1j > ul > li,
.styles_termsAndConditions__2dp1j > p {
  font-size: 14px;
  line-height: 1.5; }

.styles_Submit_container__29GQp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.styles_Submit_collapsableCustomizedName__uDaHT {
  width: 850px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--primary);
  margin-bottom: 10px; }

.styles_termsAndConditionsCheckbox__16BWR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.styles_modalHeader__1c2lL, .styles_modalSubheader__2J-gj {
  text-align: center; }

.styles_modalSubheader__2J-gj {
  margin-top: 0px; }

.styles_selectedOptionValue__TN4mb {
  margin-bottom: 20px; }

.styles_currentTime__p49FI, .styles_pendingOption__qZcbG, .styles_selectedOption__1q7oK {
  font-size: large;
  font-weight: bold; }

.styles_pendingOption__qZcbG, .styles_selectedOption__1q7oK {
  margin-bottom: 5px; }

.styles_currentTime__p49FI {
  margin-top: 20px;
  margin-bottom: 20px; }

.styles_printContainer__3zSuH {
  text-align: center; }

.styles_print__ajdeO {
  margin: 30px; }

@media (max-width: 1200px) {
  .styles_termsAndConditions__2dp1j {
    width: 100%; } }

@media print {
  .styles_print__ajdeO {
    display: none !important; } }

/*
*
* TransactionStepper Styles
*
*/
.styles_stepperContentContainer__1vVjB {
  display: grid;
  grid-template-areas: '. steps .' '. content .' '. buttons .';
  grid-gap: 10px; }

.styles_stepperContent__2UiNt {
  grid-area: content;
  margin-bottom: 20px; }

.styles_stepperStepsContainer__3u-io {
  grid-area: steps; }

.styles_stepperButtons__xA6uq {
  grid-area: buttons; }

.styles_stepperSteps__cuXBu {
  display: flex;
  justify-content: center; }

.styles_stepper__25S-M {
  text-align: center; }

@media print {
  .styles_stepperSteps__cuXBu,
  .styles_stepperButtons__xA6uq {
    display: none !important; } }

/*
*
* AccountInfo Styles
*
*/
/*
*
* SASS config
*
*/

.styles_accountDetails__N6WCc {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.styles_acctOwnerBeneRow__H1VeR,
.styles_acctNumbTotalValRow__1y-S_,
.styles_acctTypeAccountsInGroupRow__BmymD {
  display: flex;
  align-items: stretch; }
  @media (max-width: 767px) {
    .styles_acctOwnerBeneRow__H1VeR,
    .styles_acctNumbTotalValRow__1y-S_,
    .styles_acctTypeAccountsInGroupRow__BmymD {
      flex-direction: column; } }

.styles_acctOwner__2sYCL,
.styles_ben__2oYFK,
.styles_acctNum__239d9,
.styles_totalVal__1ZNld,
.styles_acctType__GLWnY,
.styles_accountsInGroup__2fgFG {
  flex-grow: 1;
  width: 50%; }
  @media (max-width: 767px) {
    .styles_acctOwner__2sYCL,
    .styles_ben__2oYFK,
    .styles_acctNum__239d9,
    .styles_totalVal__1ZNld,
    .styles_acctType__GLWnY,
    .styles_accountsInGroup__2fgFG {
      width: 100%; } }

.styles_accountsInGroupInfo__3TE18 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center; }

.styles_accountsInGroupInfoIcon__rURPm {
  cursor: pointer;
  color: var(--accent); }

.styles_detailsTitle__1a183 {
  font-weight: bold;
  margin: 5px 0; }

@media print {
  .styles_accountsInGroupInfoIcon__rURPm {
    display: none !important; }
  .styles_acctOwnerBeneRow__H1VeR,
  .styles_acctNumbTotalValRow__1y-S_,
  .styles_acctTypeAccountsInGroupRow__BmymD {
    display: flex;
    flex-direction: row; } }

/*
*
* OptionChanges Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__3lZ9g {
  text-align: center; }

.styles_optionChanges__WqoEk {
  display: grid;
  grid-template-columns: auto minmax(0px, 650px) minmax(0px, 650px) auto;
  grid-template-areas: '. pageNav pageNav .' '. accInfo details .' '. new new .';
  grid-column-gap: 10px;
  grid-row-gap: 10px; }
  @media (max-width: 1023px) {
    .styles_optionChanges__WqoEk {
      grid-template-columns: 100%;
      grid-template-areas: 'pageNav' 'accInfo' 'details' 'new'; } }

.styles_newOptionChanges__SeRKm {
  grid-area: new; }

.styles_optionChangeDetails__2A5KW {
  grid-area: details; }

.styles_accountInfo__2MckR {
  grid-area: accInfo; }

.styles_pageNav__1LeDu {
  grid-area: pageNav; }

/*
*
* AccountDetailsCard Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__3Yu8Z {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas: 'loading' 'accountDetailsCard';
  grid-column-gap: 30px;
  grid-row-gap: 10px; }
  @media (max-width: 767px) {
    .styles_container__3Yu8Z {
      grid-template-columns: 100%;
      grid-template-areas: 'loading' 'accountDetailsCard'; } }

.styles_accountDetailsCard___v4Rm {
  grid-area: accountDetailsCard; }

.styles_loading__iknPG {
  grid-area: loading; }

.styles_optionDetailsCard__1YJmI {
  grid-area: optionDetailsCard; }

/* Account Details Card Grid Layout */
.styles_accountDetails__1K7Ta {
  display: grid;
  grid-template-columns: minmax(0px, 325px) minmax(0px, 325px);
  grid-template-areas: 'accountTitle accountTitle' 'acctOwner ben' 'acctNum totalVal' 'acctType availBalance';
  padding: 10px 0; }

.styles_accountDetailsAO__19ekX {
  grid-area: acctOwner; }

.styles_accountDetailsBen__KUh0G {
  grid-area: ben; }

.styles_accountDetailsAcctNum__2_EpF {
  grid-area: acctNum; }

.styles_accountDetailsTotalVal__3wRYd {
  grid-area: totalVal; }

.styles_accountDetailsOption__F7J2a {
  grid-area: option; }

.styles_accountDetailsAcctType__2Cie6 {
  grid-area: acctType; }

.styles_accountDetailsAvailableBalance__3298i {
  grid-area: availBalance; }

/* Non-grid styles */
.styles_accountDetailsAO__19ekX,
.styles_accountDetailsBen__KUh0G,
.styles_accountDetailsAcctNum__2_EpF,
.styles_accountDetailsTotalVal__3wRYd,
.styles_accountDetailsAvailableBalance__3298i,
.styles_accountDetailsAcctType__2Cie6,
.styles_accountDetailsOption__F7J2a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.styles_detailsTitle__2CQzz {
  font-weight: bold;
  margin: 5px 0;
  text-align: center; }

@media (max-width: 767px) {
  .styles_accountDetailsCard___v4Rm, .styles_accountDetails__1K7Ta {
    grid-template-columns: 100%;
    grid-template-areas: 'accountTitle' 'acctOwner' 'ben' 'acctNum' 'totalVal' 'acctType' 'availBalance'; } }

/*
*
* DualPushButtonSwitch Styles
*
*/
/*
*
* SASS config
*
*/

.styles_DualPushButtonSwitch_container__f77QU {
  display: flex;
  margin-bottom: 10px; }

@media (max-width: 600px) {
  .styles_DualPushButtonSwitch_container__f77QU {
    margin-bottom: 10px;
    justify-content: center; }
  .styles_DualPushButtonSwitch_container__f77QU fieldset {
    border: none; }
  .styles_DualPushButtonSwitch_container__f77QU fieldset legend {
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    display: inline-block; }
  .styles_DualPushButtonSwitch_radioGroup__VJIQN {
    display: flex;
    flex-direction: column; } }

/*
*
* TargetAccount Styles
*
*/
/*
*
* SASS config
*
*/

.styles_Transfer_destinationRow__1H_mX {
  display: flex; }
  @media (max-width: 767px) {
    .styles_Transfer_destinationRow__1H_mX {
      align-items: center; } }

@media (max-width: 767px) {
  .styles_Transfer_destinationRow__1H_mX > *:first-child {
    width: 100% !important; } }

.styles_Transfer_numberInput__H7kkr {
  width: 175px;
  margin-left: 20px !important; }

.styles_iconPlaceholder__3y3pO {
  visibility: hidden; }

/*
*
* Transactions Styles
*
*/
/*
*
* SASS config
*
*/

.styles_addAccountIcon__1J0hY {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center; }

/*
*
* TransferSetup Styles
*
*/
/*
*
* SASS config
*
*/

.styles_Transfer_stepOneContainer__30h5E {
  margin: 20px 0 40px;
  display: flex;
  flex-direction: column; }

.styles_Transfer_transferTypeContainer__1sC7v {
  align-self: center; }

.styles_Transfer_accountsSelection__3ge-K {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px; }

/** Source Column Styles **/
.styles_Transfer_sourceAccountDescription__27OU5 {
  padding-top: 26px;
  max-height: 96px; }

.styles_Transfer_sourceAccountName__2hBoF {
  border-bottom: 1px solid #E0E0E0; }

.styles_Transfer_sourceAccountDescription__27OU5 > * {
  line-height: 28px; }

.styles_Transfer_sourceAccountDescription__27OU5.styles_error__9fI5V > * {
  color: var(--error); }

.styles_Transfer_sourceAccountDescription__27OU5.styles_error__9fI5V {
  padding-top: 42px; }

.styles_Transfer_sourceAccountLabel__MVZuX {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.3); }

.styles_Transfer_sourceAccountDescription__27OU5.styles_error__9fI5V .styles_Transfer_sourceAccountLabel__MVZuX {
  color: rgba(0, 0, 0, 0.3); }

.styles_Transfer_sourceAccountDescription__27OU5.styles_error__9fI5V .styles_Transfer_sourceAccountName__2hBoF {
  border-bottom: 2px solid var(--error); }

.styles_Transfer_sourceAccountDescription__27OU5 .styles_error__9fI5V {
  font-size: 12px;
  line-height: 14px;
  color: var(--error); }

.styles_Transfer_keepAccountOpenCheckbox__1Pt_O {
  white-space: nowrap; }

.styles_Transfer_iconContainer__2hDAC {
  padding-top: 2em;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.styles_Transfer_fullBalanceInfoIcon__3af_f {
  display: inline-block; }

/** Destination Column Styles **/
.styles_Transfer_inputContainer__CX4vy {
  margin-left: 10px; }

/* Transfer Total Row Styles */
.styles_Transfer_addIconContainer__3bmUq {
  display: flex;
  justify-content: center; }

.styles_Transfer_amountTotal__997cK {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .styles_Transfer_amountTotal__997cK {
      justify-content: flex-end; } }

.styles_dollarTotal__1wgFn,
.styles_percentTotal__2BL80 {
  width: 220px;
  margin-left: auto; }
  @media (max-width: 767px) {
    .styles_dollarTotal__1wgFn,
    .styles_percentTotal__2BL80 {
      width: 140px; } }

.styles_DualPushButtonSwitch_container__HW9oy {
  margin-left: 55%; }

/** Transfer Type Toggle Styles **/
.styles_Transfer_transferTypeToggleContainer__2C02V > * {
  display: inline-block;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.styles_Transfer_transferTypeToggleContainer__2C02V > fieldset {
  border: none; }

.styles_Transfer_accountsSelection__3ge-K > * {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.styles_Transfer_optionChangeIndicator__38kij {
  max-width: 4em;
  min-width: 4em;
  text-align: center; }

.styles_Transfer_optionChangeIndicator__38kij span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3); }

.styles_Transfer_optionChangeIndicator__38kij div {
  line-height: 1.5em;
  font-size: 1em; }

@media (max-width: 600px) {
  .styles_Transfer_accountsSelection__3ge-K {
    flex-direction: column; }
  .styles_Transfer_sourceAccountName__2hBoF {
    padding-top: 0; }
  .styles_DualPushButtonSwitch_container__HW9oy {
    margin-left: auto;
    justify-content: center; }
  .styles_Transfer_iconContainer__2hDAC {
    padding: 2em 0; }
  .styles_Transfer_iconContainer__2hDAC svg {
    transform: rotate(90deg); }
  .styles_Transfer_transferTypeToggleContainer__2C02V {
    display: flex;
    justify-content: center; }
  .styles_Transfer_transferType_radioButtonGroup__2w7W3 > *:first-child {
    display: block !important; }
  .styles_Transfer_sourceColumn__GULuY {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .styles_Transfer_inputContainer__CX4vy {
    margin: 12px 0 0 0;
    width: 100%; }
  .styles_Transfer_sourceColumn__GULuY > * {
    display: flex;
    justify-content: center; }
  .styles_Transfer_sourceContainer__21ZuN {
    flex-direction: column; }
  .styles_Transfer_keepAccountOpenCheckbox__1Pt_O {
    white-space: nowrap; }
  .styles_Transfer_sourceColumn__GULuY .styles_Transfer_iconContainer__2hDAC {
    padding-top: 1em; }
  .styles_Transfer_sourceColumn__GULuY .styles_Transfer_iconContainer__2hDAC svg {
    transition: all;
    transform: rotate(90deg); }
  .styles_Transfer_destinationColumn__ft4jR {
    width: 100%;
    text-align: center; }
  .styles_Transfer_optionChangeIndicator__38kij.styles_row-0__1PcVz, .styles_Transfer_optionChangeIndicator__38kij {
    margin: 12px 0 0 0;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    text-align: left; } }

/*
*
* TransferTermsAndConditions Styles
*
*/
/*
*
* SASS config
*
*/

/* centers checkbox ripple effect after adding margin */
.styles_Transfer_termsCheckbox__19ahU > div > div > div > div + div > span {
  overflow: visible !important; }

.styles_Transfer_termsCheckbox__19ahU > div > div > div > div + div > span > div {
  left: -12px !important; }

.styles_Transfer_reviewCloseAccount__1ZFDl {
  grid-area: reviewCloseAccount; }

.styles_Transfer_reviewAccountNumber__1x8uN {
  grid-area: reviewAccountNum; }

.styles_Transfer_reviewBeneficiaryName__38oGB {
  grid-area: reviewBen; }

.styles_Transfer_reviewAccountType__LTJv0 {
  grid-area: reviewAccountType; }

.styles_Transfer_reviewAccountOption__u_Vny {
  grid-area: reviewAccountOption; }

.styles_Transfer_reviewOptionChange__2jFfn {
  grid-area: reviewOptionChange; }

.styles_Transfer_reviewAmount__2x39V {
  grid-area: reviewAmount; }

.styles_Transfer_reviewTotalAmount__2CwaS {
  grid-area: reviewTotalAmount; }

.styles_Transfer_reviewCloseAccount__1ZFDl,
.styles_Transfer_reviewAccountNumber__1x8uN,
.styles_Transfer_reviewBeneficiaryName__38oGB,
.styles_Transfer_reviewAccountType__LTJv0,
.styles_Transfer_reviewAccountOption__u_Vny,
.styles_Transfer_reviewOptionChange__2jFfn {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_TransferFrom_reviewContainer__17obZ {
  margin-bottom: 20px;
  display: grid;
  grid-column-gap: 5px;
  column-gap: 5px;
  grid-template-areas: '. reviewCloseAccount reviewCloseAccount reviewCloseAccount reviewCloseAccount .' 'reviewAccountNum reviewBen reviewAccountType reviewAccountOption reviewOptionChange reviewAmount'; }

.styles_TransferFrom_reviewContainerCompact__2IBF- {
  display: grid;
  grid-column-gap: 5px;
  column-gap: 5px;
  grid-template-areas: '. reviewCloseAccount reviewCloseAccount reviewCloseAccount .' 'reviewAccountNum reviewBen reviewAccountType reviewAccountOption reviewAmount'; }

.styles_TransferTo_reviewContainer__OkHER {
  display: grid;
  grid-column-gap: 5px;
  column-gap: 5px;
  grid-template-areas: '. reviewCloseAccount reviewCloseAccount reviewCloseAccount reviewCloseAccount .' 'reviewAccountNum reviewBen reviewAccountType reviewAccountOption reviewOptionChange reviewAmount' '. . . . . reviewTotalAmount'; }

.styles_TransferTo_reviewContainerCompact__KB-Pk {
  display: grid;
  grid-column-gap: 5px;
  column-gap: 5px;
  grid-template-areas: '. reviewCloseAccount reviewCloseAccount reviewCloseAccount .' 'reviewAccountNum reviewBen reviewAccountType reviewAccountOption reviewAmount' '. . . . reviewTotalAmount'; }

.styles_Transfer_reviewCloseAccount__1ZFDl {
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px; }

.styles_Transfer_reviewCloseAccount__1ZFDl > div:nth-of-type(1),
.styles_Transfer_reviewTotalAmount__2CwaS > div:nth-of-type(1) {
  padding-right: 15px;
  font-weight: bold; }

.styles_Transfer_reviewCloseAccount__1ZFDl > div:nth-of-type(2) {
  font-weight: normal; }

.styles_Transfer_reviewAccountNumber__1x8uN,
.styles_Transfer_reviewBeneficiaryName__38oGB,
.styles_Transfer_reviewAccountType__LTJv0,
.styles_Transfer_reviewAccountOption__u_Vny,
.styles_Transfer_reviewOptionChange__2jFfn {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_Transfer_detailsTitle__sj9VS {
  font-weight: bold;
  margin: 5px 0;
  text-align: center; }

.styles_Transfer_detail__Wh26r {
  margin-top: 10px; }

.styles_Transfer_reviewOptionChange__2jFfn {
  align-self: center; }

.styles_Transfer_reviewAmount__2x39V {
  display: flex;
  flex-direction: column;
  justify-self: end;
  text-align: right; }

.styles_Transfer_reviewTotalAmount__2CwaS {
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.styles_Transfer_reviewTotalAmount__2CwaS > div {
  border-top: 1px solid var(--text); }

.styles_mobile__3S373 {
  display: none; }

.styles_Transfer_termsAndConditions__1S46k h2,
.styles_Transfer_termsAndConditions__1S46k h3 {
  margin-bottom: 0;
  text-align: center; }

@media (max-width: 767px) {
  .styles_Transfer_termsAndConditions__1S46k h2 {
    font-size: medium; } }

@media (max-width: 767px) {
  .styles_Transfer_termsAndConditions__1S46k h3 {
    font-size: small; } }

.styles_Transfer_termsAndConditions__1S46k {
  display: flex;
  flex-direction: column;
  margin-top: 50px; }

.styles_Transfer_termsAndConditions__1S46k > .styles_Transfer_termsCheckbox__19ahU {
  display: flex;
  justify-content: center; }

@media (max-width: 767px) {
  .styles_Transfer_termsAndConditions__1S46k p,
  .styles_Transfer_termsAndConditions__1S46k ul > li {
    font-size: x-small; } }

@media (max-width: 767px) {
  .styles_Transfer_termsAndConditions__1S46k span {
    font-size: small; } }

@media (max-width: 600px) {
  .styles_TransferFrom_reviewContainer__17obZ {
    display: grid;
    grid-template-areas: 'reviewCloseAccount reviewCloseAccount reviewCloseAccount' 'reviewAccountNum reviewOptionChange reviewAmount'; }
  .styles_TransferFrom_reviewContainerCompact__2IBF- {
    display: grid;
    grid-template-areas: 'reviewCloseAccount reviewCloseAccount' 'reviewAccountNum reviewAmount'; }
  .styles_TransferTo_reviewContainer__OkHER {
    display: grid;
    grid-template-areas: 'reviewCloseAccount reviewCloseAccount reviewCloseAccount' 'reviewAccountNum reviewOptionChange reviewAmount' '. . reviewTotalAmount'; }
  .styles_TransferTo_reviewContainerCompact__KB-Pk {
    display: grid;
    grid-template-areas: 'reviewCloseAccount reviewCloseAccount' 'reviewAccountNum reviewAmount' '. reviewTotalAmount'; }
  .styles_Transfer_reviewBeneficiaryName__38oGB {
    display: none; }
  .styles_Transfer_reviewOptionChange__2jFfn {
    align-self: start; }
  .styles_desktop__5i5ZD {
    display: none; }
  .styles_mobile__3S373 {
    display: unset; } }

/*
*
* Transfer Styles
*
*/
/*
*
* SASS config
*
*/

.styles_Transfer_container__3QVO2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 10px; }

.styles_Transfer_stepperButton__1IJwZ {
  margin-right: 10px; }

.styles_InfoIcon_container__15lwH {
  vertical-align: text-bottom; }

.styles_Transfer_stepperContent__2XoYi {
  margin-bottom: 20px; }

.styles_step_0__1Bmrt {
  width: 100%; }

.styles_step_1__9vaWo {
  width: 80%; }

.styles_step_2__3Ixac {
  max-width: 850px; }

.styles_currentTime__dzJmT {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
  font-size: large; }

.styles_print__WFwes {
  margin: 30px;
  text-align: center; }

@media print {
  .styles_print__WFwes {
    display: none !important; } }

/*
*
* SASS config
*
*/

.styles_closeIcon__1PjzH {
  text-align: right; }

.styles_termsContainer__36Omv {
  padding: 20px;
  padding-top: 10px; }

.styles_terms__1Ai0g {
  font-size: small;
  margin: 0 10%; }
  @media (max-width: 767px) {
    .styles_terms__1Ai0g {
      margin: 0 5%; } }

.styles_termsHeader__nfhS8 {
  text-align: center;
  margin: 20px 0px; }

/*
*
* Transfers Styles
*
*/
/* Page Grid Layout */
/*
*
* SASS config
*
*/

.styles_transfersContainer__24Hnq {
  display: grid;
  grid-template-columns: auto minmax(0px, 650px) minmax(0px, 650px) auto;
  grid-template-areas: '. pageNav pageNav .' '. details details .' '. transfer transfer .' '. learnMoreButton learnMoreButton .';
  grid-column-gap: 30px;
  grid-row-gap: 10px; }

.styles_pageNav__2cDvK {
  grid-area: pageNav; }

.styles_detailsContainer__3S9fZ {
  grid-area: details; }

.styles_children__1t51q {
  grid-area: transfer; }

.styles_learnMoreButton__2QXXz {
  grid-area: learnMoreButton; }

.styles_loading__AMuie {
  min-height: 24px; }

.styles_scheduledTable__GDRNT,
.styles_historyTable__3Qy32 {
  width: 100%; }

.styles_scheduledContainer__RXNC6,
.styles_historyContainer__1tjHE {
  margin-bottom: 10px; }

@media (max-width: 1200px) {
  .styles_transfersContainer__24Hnq {
    grid-template-columns: 100%;
    grid-template-areas: 'pageNav' 'details' 'transfer' 'learnMoreButton';
    grid-column-gap: 10px; } }

.styles_learnMoreButton__2QXXz {
  text-align: center;
  padding: 20px;
  margin-bottom: 10px; }

/*
*
* OneTime Styles
*
*/
/*
*
* SASS config
*
*/

.styles_step0Container__23Dme {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_step0AccountsContainer__35tVt {
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: auto 25px auto 25px 25px;
  grid-template-areas: 'accounts . amount . remove'; }
  @media (max-width: 767px) {
    .styles_step0AccountsContainer__35tVt {
      grid-template-columns: 100%;
      grid-template-areas: 'accounts' 'amount' 'remove'; } }

.styles_accountListDropdown__1V7rX {
  grid-area: accounts; }

.styles_amountInput__1P03x {
  grid-area: amount; }

.styles_removeAccountIcon__keLpO {
  grid-area: remove; }

.styles_removeAccountIcon__keLpO {
  align-self: center; }

.styles_totalAndErrorsContainer__3Saem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px; }

.styles_totalAndErrorsContainer__3Saem > div {
  margin-bottom: 10px; }

.styles_totalAmountStep0__1lMPL {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  font-size: medium; }

.styles_step1Container__jZMe8 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_datePicker__2dtbD {
  width: 100%;
  max-width: 280px; }
  @media (max-width: 1023px) {
    .styles_datePicker__2dtbD {
      max-width: 256px; } }

.styles_bankAccount__2Mg7u {
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  align-items: center;
  justify-content: center;
  margin-top: 10px; }
  @media (max-width: 1023px) {
    .styles_bankAccount__2Mg7u {
      max-width: 280px;
      grid-column: span 1;
      align-items: center; } }

.styles_bankAccount__2Mg7u li {
  font-size: small;
  background: red; }

.styles_bankMenu__1oR1e {
  background-color: red; }

.styles_addBankIcon__1CArW,
.styles_removeAccount__1exbQ {
  cursor: pointer; }

.styles_reviewContainer__y5aHS {
  display: grid;
  justify-content: space-between;
  grid-column-gap: 10px;
  grid-template-areas: 'reviewAccountNum reviewBen reviewBank reviewDate reviewAmount'; }
  @media (max-width: 1023px) {
    .styles_reviewContainer__y5aHS {
      font-size: small;
      grid-template-columns: auto auto auto auto auto;
      grid-template-areas: 'reviewAccountNum reviewBen reviewBank reviewDate reviewAmount'; } }
  @media (max-width: 767px) {
    .styles_reviewContainer__y5aHS {
      font-size: x-small;
      grid-row-gap: 5px;
      grid-column-gap: 5px; } }

@media (max-width: 767px) {
  .styles_detailsTitle__1O4h9 {
    height: 24px; } }

.styles_reviewAccountNumber__3LVoX {
  grid-area: 'reviewAccountNum'; }

.styles_reviewBeneficiaryName__24CA6 {
  grid-area: 'reviewBen'; }

.styles_reviewBankAccount__1YyLo {
  grid-area: 'reviewBank'; }

.styles_reviewDate__3g6_Y {
  grid-area: 'reviewDate'; }

.styles_reviewAmount__2By-w {
  grid-area: 'reviewAmount'; }

.styles_totalAmountStep2__1o8s_ {
  font-weight: bold;
  border-top: 1px solid var(--text);
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  grid-column-start: 4;
  justify-content: flex-end; }
  @media (max-width: 767px) {
    .styles_totalAmountStep2__1o8s_ {
      font-size: small; } }

.styles_reviewAccountNumber__3LVoX,
.styles_reviewBankAccount__1YyLo,
.styles_reviewBeneficiaryName__24CA6,
.styles_reviewDate__3g6_Y {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_reviewAmount__2By-w {
  display: flex;
  flex-direction: column;
  justify-self: end;
  text-align: right; }
  @media (max-width: 1023px) {
    .styles_reviewAmount__2By-w {
      justify-self: end; } }

.styles_totalAmountStep0__1lMPL > div:nth-of-type(1),
.styles_totalAmountStep2__1o8s_ > div:nth-of-type(1) {
  margin-right: 15px; }

.styles_detailsTitle__1O4h9 {
  font-size: medium;
  font-weight: bold;
  margin: 5px 0;
  text-align: center; }
  @media (max-width: 1023px) {
    .styles_detailsTitle__1O4h9 {
      font-size: small; } }

.styles_termsContainer__2KWeB {
  display: grid;
  justify-items: center;
  margin-top: 50px;
  grid-template-columns: auto minmax(0px, 850px) auto;
  grid-template-areas: '. terms .' '. checkbox .'; }

.styles_terms__ksbU0 {
  grid-area: terms; }

.styles_checkbox__13a6R {
  grid-area: checkbox; }

@media (max-width: 767px) {
  .styles_checkbox__13a6R {
    font-size: x-small; } }

.styles_printContainer__1S5hm {
  text-align: center; }

.styles_currentTime__2vHVa {
  margin-top: 30px;
  font-weight: bold;
  font-size: large; }

.styles_print__qI3ON {
  margin: 40px; }

@media print {
  .styles_print__qI3ON {
    display: none !important; } }

/*
*
* TermsConditions Styles
*
*/
/*
*
* SASS config
*
*/

.styles_termsConditions_container__xatva {
  margin-bottom: 30px;
  text-align: left; }

.styles_termsConditions_container__xatva ul li,
.styles_termsConditions_container__xatva p {
  font-size: 14px;
  line-height: 1.5; }
  @media (max-width: 767px) {
    .styles_termsConditions_container__xatva ul li,
    .styles_termsConditions_container__xatva p {
      font-size: x-small; } }

@media (max-width: 767px) {
  .styles_termsConditions_container__xatva i {
    font-size: small; } }

.styles_termsConditions_container__xatva p {
  font-weight: bold; }

.styles_termsConditions_modalHeader__XnwI7,
.styles_termsConditions_modalSubheader__NnqRK {
  text-align: center; }

.styles_termsConditions_modalHeader__XnwI7 {
  font-size: 24px; }

.styles_termsConditions_modalSubheader__NnqRK {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0px; }

.styles_termsConditions_modalSubheader__NnqRK:nth-of-type(2) {
  margin-bottom: 40px; }

/*
*
* Recurring Styles
*
*/
/*
*
* SASS config
*
*/

.styles_step0Container__2RJrM {
  display: grid;
  grid-template-columns: auto 25px auto;
  justify-content: center;
  grid-template-areas: 'account . amount' 'bankAccount bankAccount bankAccount' 'error error error'; }
  @media (max-width: 1023px) {
    .styles_step0Container__2RJrM {
      grid-template-columns: 100%;
      grid-template-areas: 'account' 'amount' 'bankAccount' 'error'; } }

.styles_accountContainer__1g2PQ {
  grid-area: account; }

.styles_amount__est-9 {
  grid-area: amount; }

.styles_bankAccount__3HWT9 {
  grid-area: bankAccount; }

.styles_errorsContainer__Csjsb {
  grid-area: error; }

.styles_accountContainer__1g2PQ {
  position: relative; }

.styles_account__1APi2 {
  border-bottom: 1px solid #afafaf;
  padding-bottom: 5px;
  text-overflow: ellipsis; }

.styles_accountLabel__1kOb8 {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top 0px;
  color: rgba(0, 0, 0, 0.3); }

.styles_currencyInput__10_ct {
  width: 125px; }
  @media (max-width: 1023px) {
    .styles_currencyInput__10_ct {
      width: 100%; } }

.styles_bankAccount__3HWT9 {
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  justify-self: center; }
  @media (max-width: 1023px) {
    .styles_bankAccount__3HWT9 {
      grid-column: span 1;
      align-items: center; } }

.styles_noBankAccount__2UZg8 {
  display: flex;
  grid-column: span 3;
  justify-self: center;
  flex-direction: column;
  margin-top: 10px; }

.styles_errorsContainer__Csjsb {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  align-self: center; }
  @media (max-width: 1023px) {
    .styles_errorsContainer__Csjsb {
      width: 256px; } }

.styles_error__3-qfr {
  color: var(--error);
  text-align: center; }

.styles_addButton__1OseC {
  cursor: pointer;
  align-self: center; }

.styles_step1Container__32Xah {
  display: flex;
  align-items: center;
  flex-direction: column; }

.styles_step1RadioButtons__KBK6v {
  margin-bottom: 15px; }

.styles_step1Date1__1woKO,
.styles_step1Date2__2sFWu,
.styles_endDate__f7roR {
  margin: 10px 0; }

.styles_step1Date1__1woKO,
.styles_step1Date2__2sFWu {
  display: flex;
  flex-direction: column; }

.styles_endDate__f7roR,
.styles_step1Date1__1woKO,
.styles_step1Date2__2sFWu {
  width: 256px;
  text-align: left; }

.styles_reviewContainer__ef8cG {
  margin-bottom: 50px;
  text-align: center;
  display: grid;
  justify-content: center;
  grid-template-areas: '. accountNum .' '. ben .' '. sched .' '. nextDate .' '. endDate .' '. amount .' '. bankAccount .'; }
  @media (max-width: 1023px) {
    .styles_reviewContainer__ef8cG {
      font-size: small;
      display: grid;
      grid-template-columns: 100%;
      grid-template-areas: 'accountNum' 'ben' 'sched' 'nextDate' 'endDate' 'amount' 'bankAccount'; } }

.styles_reviewInfoAccountNum__3E_WN {
  grid-area: accountNum; }

.styles_reviewInfoBen__3czqw {
  grid-area: ben; }

.styles_reviewInfoSched__3gso_ {
  grid-area: sched; }

.styles_reviewInfoNextDate__2b6d- {
  grid-area: nextDate; }

.styles_reviewInfoEndDate__2U3rc {
  grid-area: endDate; }

.styles_reviewInfoAmount__21wdV {
  grid-area: amount; }

.styles_reviewInfoBankAccount__3sBwq {
  grid-area: bankAccount; }

.styles_reviewInfoAccountNum__3E_WN,
.styles_reviewInfoBen__3czqw,
.styles_reviewInfoSched__3gso_,
.styles_reviewInfoNextDate__2b6d-,
.styles_reviewInfoEndDate__2U3rc,
.styles_reviewInfoAmount__21wdV,
.styles_reviewInfoBankAccount__3sBwq {
  display: flex;
  margin: 10px 0px; }
  @media (max-width: 1023px) {
    .styles_reviewInfoAccountNum__3E_WN,
    .styles_reviewInfoBen__3czqw,
    .styles_reviewInfoSched__3gso_,
    .styles_reviewInfoNextDate__2b6d-,
    .styles_reviewInfoEndDate__2U3rc,
    .styles_reviewInfoAmount__21wdV,
    .styles_reviewInfoBankAccount__3sBwq {
      flex-direction: column; } }

.styles_detailsTitle__3ON5H {
  font-weight: bold;
  margin-right: 5px; }
  @media (max-width: 1023px) {
    .styles_detailsTitle__3ON5H {
      margin: 0px; } }

.styles_termsContainer__12FzG {
  display: grid;
  justify-items: center;
  margin-top: 50px;
  grid-template-columns: auto minmax(0px, 850px) auto;
  grid-template-areas: '. terms .' '. checkbox .'; }
  @media (max-width: 1023px) {
    .styles_termsContainer__12FzG {
      grid-template-columns: 100%;
      grid-template-areas: 'terms' 'checkbox'; } }

.styles_terms__1761O {
  grid-area: terms; }

.styles_checkbox__2e2eU {
  grid-area: checkbox; }

.styles_printContainer__20HMk {
  text-align: center; }

.styles_currentTime__1m4NU {
  margin-top: 30px;
  font-weight: bold;
  font-size: large; }

.styles_print__2Z6Sn {
  margin: 40px; }

@media print {
  .styles_print__2Z6Sn {
    display: none !important; } }

/*
*
* Special Styles
*
*/
/*
*
* SASS config
*
*/

.styles_occasionSelectionContainer__1ZW05 {
  display: grid;
  grid-template-columns: 175px;
  justify-content: center;
  grid-template-areas: 'bdayOccasion' 'annualEventOccasion' 'annualEventDate'; }

.styles_occasionSelectionBday__2zrND {
  grid-area: bdayOccasion; }

.styles_occasionSelectionAnnualEvent__3gZad {
  grid-area: annualEventOccasion; }

.styles_annualEventDateContainer__1-FBo {
  grid-area: annualEventDate; }

.styles_annualEventDateContainer__1-FBo {
  text-align: left;
  margin-top: 25px; }
  @media (max-width: 1023px) {
    .styles_annualEventDateContainer__1-FBo {
      margin-bottom: 25px; } }

.styles_radioButtonsLabelText__19Eeb {
  flex-shrink: 0;
  padding-right: 2px; }

.styles_radioButtonLabel__1-LRv {
  display: flex;
  flex-wrap: nowrap;
  align-items: center; }

.styles_accounts__2VKpN {
  grid-area: accounts; }

.styles_amount__oe4MF {
  grid-area: amount; }

.styles_birthdayContainer__1pm4P {
  grid-area: bday; }

.styles_endDateContainer__2Zivd {
  grid-area: endDate; }

.styles_removeAccount__2JPwA {
  grid-area: remove; }

.styles_endDateContainer__2Zivd {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .styles_endDateContainer__2Zivd {
      width: 100%; } }

.styles_removeAccount__2JPwA {
  align-self: center; }
  @media (max-width: 1023px) {
    .styles_removeAccount__2JPwA {
      justify-self: center; } }

.styles_hideIcon__3yVCa {
  opacity: 0; }

.styles_step0Container__2iNJQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.styles_step0Birthday__5loS4 {
  margin-bottom: 5px;
  display: grid;
  align-items: start;
  grid-row-gap: 15px;
  grid-template-columns: [accounts] auto 25px [amount] auto 25px [bday] 100px 25px [endDate] auto [remove] 25px; }
  @media (max-width: 1023px) {
    .styles_step0Birthday__5loS4 {
      grid-template-areas: 'accounts' 'amount' 'bday' 'endDate' 'remove';
      grid-template-columns: 100%; } }

.styles_step0AnnualEvent__8-amR {
  display: grid;
  align-items: start;
  grid-row-gap: 15px;
  grid-template-columns: [accounts] auto 25px [amount] auto 25px [endDate] auto [remove] 25px; }
  @media (max-width: 1023px) {
    .styles_step0AnnualEvent__8-amR {
      grid-template-areas: 'accounts' 'amount' 'endDate' 'remove';
      grid-template-columns: 100%; } }

@media (max-width: 1023px) {
  .styles_step0Birthday__5loS4:nth-child(n+2),
  .styles_step0AnnualEvent__8-amR:nth-child(n+2) {
    margin-top: 25px; } }

.styles_currencyInput__1nPNb {
  width: 125px; }
  @media (max-width: 1023px) {
    .styles_currencyInput__1nPNb {
      width: 100%; } }

.styles_errors__2ysEM,
.styles_totalAndErrorsContainer__H2cWq {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px; }

.styles_totalAndErrorsContainer__H2cWq > * {
  margin-bottom: 5px; }

.styles_detail__Kdk6E {
  margin-top: 10px; }

.styles_totalAmountStep0__23Lb5 > div:nth-of-type(1),
.styles_totalAmountStep2__vZ2dZ > div:nth-of-type(1) {
  margin-right: 15px; }

.styles_totalAmountStep0__23Lb5 {
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  font-weight: bold; }

.styles_birthdayContainer__1pm4P {
  position: relative;
  margin-top: 21px;
  text-align: left; }
  @media (max-width: 767px) {
    .styles_birthdayContainer__1pm4P {
      width: 100%; } }

.styles_birthday__lDJYW {
  padding-bottom: 6.5px;
  border-bottom: 1px solid #afafaf;
  min-height: 26px; }

.styles_birthdayLabel__1z41A {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top 0px;
  color: rgba(0, 0, 0, 0.54); }

.styles_bankAccount__lFgE_ {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 18px; }

.styles_reviewContainer__3NVH1 {
  display: grid;
  justify-content: space-between;
  grid-column-gap: 10px;
  grid-template-areas: 'reviewBen reviewAccountNum reviewBank reviewNextDate reviewEndDate reviewAmount'; }
  @media (max-width: 1023px) {
    .styles_reviewContainer__3NVH1 {
      font-size: small; } }
  @media (max-width: 767px) {
    .styles_reviewContainer__3NVH1 {
      grid-row-gap: 15px;
      grid-template-areas: 'reviewBank reviewBank reviewBank reviewBank reviewBank' 'reviewBen reviewAccountNum reviewNextDate reviewEndDate reviewAmount';
      font-size: x-small; } }

.styles_reviewAccountNumber__1A-kQ {
  grid-area: reviewAccountNum; }

.styles_reviewAmount__pArYP {
  grid-area: reviewAmount; }

.styles_reviewBankAccount__3xu8N {
  grid-area: reviewBank; }

.styles_reviewBeneficiaryName__3fEp8 {
  grid-area: reviewBen; }

.styles_reviewEndDate__jEamN {
  grid-area: reviewEndDate; }

.styles_reviewNextDate__1TgP5 {
  grid-area: reviewNextDate; }

.styles_reviewAccountNumber__1A-kQ,
.styles_reviewBankAccount__3xu8N,
.styles_reviewBeneficiaryName__3fEp8,
.styles_reviewEndDate__jEamN,
.styles_reviewNextDate__1TgP5 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_reviewBankAccount__3xu8N {
  justify-self: center; }

.styles_reviewAmount__pArYP {
  display: flex;
  flex-direction: column;
  justify-self: end;
  text-align: right; }

.styles_totalAmountStep2__vZ2dZ {
  font-weight: bold;
  border-top: 1px solid var(--text);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.styles_detailsTitle__2GWub {
  font-weight: bold;
  margin: 5px 0;
  text-align: center; }
  @media (max-width: 767px) {
    .styles_detailsTitle__2GWub {
      height: 24px; } }

@media (max-width: 767px) {
  .styles_reviewBankAccount__3xu8N > .styles_detailsTitle__2GWub {
    height: 100%; } }

.styles_termsContainer__FIQDD {
  display: grid;
  justify-items: center;
  margin-top: 50px;
  grid-template-columns: auto minmax(0px, 850px) auto;
  grid-template-areas: '. terms .' '. checkbox .'; }

.styles_terms__n7TbC {
  grid-area: terms; }

.styles_checkbox__1rS9s {
  grid-area: checkbox; }

.styles_detail__Kdk6E {
  margin-top: 0px; }

.styles_icon__18vfe {
  cursor: pointer; }

.styles_currentTime__xM0HL {
  margin-top: 30px;
  font-weight: bold;
  font-size: large; }

.styles_print__2FtH5 {
  margin: 40px; }

@media print {
  .styles_print__2FtH5 {
    display: none !important; }
  .styles_reviewContainer__3NVH1 {
    display: grid;
    grid-template-areas: 'reviewBen reviewAccountNum reviewBank reviewNextDate reviewEndDate reviewAmount'; } }

/*
*
* GiactRejectionModal Styles
*
*/
/*
*
* SASS config
*
*/

@media (max-width: 767px) {
  .styles_giactText__djgtn {
    font-size: small; } }

/*
*
* SASS config
*
*/

.styles_addBankModalContainer__Ust-u {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.styles_AddBank_formAndCheckContainer__2_T7l {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-areas: 'accountForm checkImage';
  grid-gap: 5px;
  gap: 5px; }
  @media (max-width: 1023px) {
    .styles_AddBank_formAndCheckContainer__2_T7l {
      grid-template-columns: 35% 65%; } }
  @media (max-width: 767px) {
    .styles_AddBank_formAndCheckContainer__2_T7l {
      grid-template-columns: 100%;
      grid-template-areas: 'checkImage' 'accountForm'; } }

.styles_AddBank_formAndCheckContainer__2_T7l {
  grid-area: formAndCheckContainer; }

.styles_AddBank_accountForm__2b24Z {
  grid-area: accountForm; }

.styles_checkImage__VkSjb {
  grid-area: checkImage; }

.styles_dialogActions__37J-Z {
  grid-area: dialogActions; }

.styles_AddBank_requiredLanguage__2VGlB {
  grid-area: requiredLanguage; }

.styles_AddBank_accountForm__2b24Z {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 15px; }
  @media (max-width: 767px) {
    .styles_AddBank_accountForm__2b24Z {
      padding-left: 0; } }

.styles_AddBank_checkImage__1y004 {
  width: 100%; }

.styles_AddBank_requiredLanguage__2VGlB {
  padding: 0 30px; }
  @media (max-width: 1023px) {
    .styles_AddBank_requiredLanguage__2VGlB {
      font-size: small;
      padding: 0 15px; }
      .styles_AddBank_requiredLanguage__2VGlB ul {
        padding-left: 25px; } }

/*
*
* Contributions Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__181wQ {
  text-align: center; }

.styles_contributions__NRkx- {
  margin-top: 25px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-columns: auto minmax(0px, 650px) minmax(0px, 650px) auto;
  grid-template-areas: '. breadcrumbs breadcrumbs .' '. accountDetails accountDetails .' '. contributionsTabs contributionsTabs .';
  width: 100%; }

.styles_breadcrumbs__1Z-0- {
  grid-area: breadcrumbs; }

.styles_accountDetails__2SLVm {
  grid-area: accountDetails; }

.styles_contributionsTabs__3cBcE {
  grid-area: contributionsTabs; }

.styles_withdrawalTypeContainer__axiS_ {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  max-width: 250px; }

.styles_withdrawalTypeContainer__axiS_ > div {
  margin-bottom: 20px; }

.styles_partialBalanceMessaging__1zUp6 {
  display: flex;
  flex-direction: column;
  font-size: small;
  max-width: 250px; }

.styles_partialBalanceMessaging__1zUp6 > ul {
  margin-top: 0px; }

.styles_partialBalanceMessaging__1zUp6 > ul > li:nth-child(2) {
  margin-top: 12px; }

.styles_sellDate__2TifI {
  display: flex;
  align-items: center; }

.styles_amountDateContainer__2uRvu {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: auto; }

.styles_amountDateContainer__2uRvu > div {
  margin-bottom: 20px; }

.styles_partialBalanceMessaging__sHWpN {
  display: flex;
  flex-direction: column;
  font-size: small;
  max-width: 250px; }

.styles_partialBalanceMessaging__sHWpN > ul {
  margin-top: 0px; }

.styles_partialBalanceMessaging__sHWpN > ul > li:nth-child(2) {
  margin-top: 12px; }

.styles_sellDate__3hLG_ {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

/*
*
* StepComponents Styles
*
*/
/*
*
* SASS config
*
*/

.styles_row__20NM9 {
  display: flex; }

.styles_rowCentered__2qswM {
  display: flex;
  justify-content: center; }

.styles_checkAddressContainer__1k0DP p {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0; }

.styles_checkMailingMessage__1XYvu {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px; }

.styles_electronicRequestMessage__2uP6l {
  margin-bottom: 15px; }

.styles_schoolAddressContainer__3hVB- {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_schoolAddressContainer__3hVB- > div {
  margin-bottom: 20px; }

.styles_retainedSchoolAddress__Wlulb {
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
  margin: auto;
  margin-bottom: 20px; }

.styles_paymentType__4MlwV {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px; }

.styles_searchSchools__1lgWw {
  text-align: center; }

.styles_error__3j18W {
  color: var(--error);
  max-width: 360px; }

.styles_ugmautma__3LUhQ {
  max-width: 450px;
  margin-bottom: 15px; }

.styles_summaryContainer__29eeX {
  margin: 20px; }

.styles_summaryContainer__29eeX p {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0; }

.styles_subSummaryContainer__1dX6r {
  margin: auto;
  width: 50%; }

.styles_summaryTitle__1rPDM,
.styles_summarySubtitle__3HSIG,
.styles_summaryTable__E9RHI
.styles_createdTime__24uBr,
.styles_print__wPSzw {
  margin-top: 10px; }

.styles_createdTime__24uBr,
.styles_print__wPSzw {
  text-align: center; }

.styles_createdTime__24uBr {
  font-weight: bold;
  font-size: large;
  margin: 30px 0; }

.styles_summaryTitle__1rPDM {
  margin-bottom: 20px;
  text-align: center; }

.styles_summarySubtitle__3HSIG {
  margin-bottom: 3px; }

.styles_summaryTable__E9RHI {
  min-height: '175px';
  margin: 20px;
  text-align: center; }

.styles_summaryTable__E9RHI > .styles_totalAmountOf__1ZPvs {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: right;
  padding-right: 20px; }

/*
*
* SASS config
*
*/

.styles_reviewContainer__2DPY_ {
  text-align: center; }

.styles_recipient__30rlu {
  display: grid;
  grid-template-columns: auto minmax(0px, 320px) minmax(0px, 320px) auto;
  grid-template-areas: '. recipientInfo recipientAddress .';
  grid-column-gap: 10px;
  grid-row-gap: 10px; }
  @media (max-width: 1023px) {
    .styles_recipient__30rlu {
      grid-template-columns: auto minmax(0px, 250px) auto;
      grid-template-areas: '. recipientInfo .' '. recipientAddress .'; } }

.styles_recipientInfo__3A97i {
  grid-area: recipientInfo; }

.styles_recipientAddress__2IdMg {
  grid-area: recipientAddress; }

.styles_recipientInfo__3A97i,
.styles_recipientAddress__2IdMg {
  text-align: left; }

.styles_detailsTitle__mY2FZ {
  font-size: large;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 3px; }

.styles_termsAndConditions__3Ksmp {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px; }

.styles_termsAndConditions__3Ksmp > h5,
.styles_termsAndConditions__3Ksmp > h3,
.styles_termsAndConditions__3Ksmp > h2 {
  margin-bottom: 0;
  text-align: center; }

.styles_termsAndConditions__3Ksmp > ul > li,
.styles_termsAndConditions__3Ksmp > p {
  font-size: 14px;
  line-height: 1.5; }

.styles_termsAndConditions__3Ksmp > p {
  margin-top: 1em;
  margin-bottom: 1em; }

.styles_termsAndConditionsCheckbox__1WD3T {
  display: flex;
  justify-content: center; }

/*
*
* NewWithdrawals Styles
*
*/
/*
*
* SASS config
*
*/

.styles_stepperButton__1wLR3 {
  margin: 0 5px; }

.styles_stepperButtons__2yk4m {
  display: flex;
  margin-top: 25px; }

.styles_radioButtonsInfoIcon__2wiuo {
  opacity: 1;
  line-height: 1.25; }

.styles_radioButtonsLabelText__3S7a4 {
  flex-shrink: 0;
  padding-right: 2px; }

.styles_radioButtonsLabel__2LmHs {
  display: flex;
  flex-wrap: nowrap; }

/*
*
* WithdrawalDetails Styles
*
*/
.styles_container__31JlS {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'loading' 'details';
  grid-column-gap: 30px;
  grid-row-gap: 10px; }

.styles_accountDetailsCard__NwNgx {
  grid-area: details; }

.styles_loading__1oxdt {
  grid-area: loading; }

/* Account Details Card Grid Layout */
.styles_cardContentContainerNonProportional__1xtS0 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'accountTitle accountTitle' 'accountOwner beneficiary' 'acctNum acctType' 'option marketVal' 'availBalance availBalance';
  padding: 10px 0;
  grid-column-gap: 50px;
  grid-row-gap: 10px; }

.styles_cardContentContainerProportional__6nmzC {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'accountTitle accountTitle' 'accountOwner beneficiary' 'acctNum acctType' 'groupBalance groupBalance';
  padding: 10px 0;
  grid-column-gap: 50px;
  grid-row-gap: 10px; }

.styles_acctNum__3r6by {
  grid-area: acctNum; }

.styles_acctType__1z-hX {
  grid-area: acctType; }

.styles_accountOwner__b-A4c {
  grid-area: accountOwner; }

.styles_availableBalance__6YhIX {
  grid-area: availBalance; }

.styles_beneficiary__3-ziW {
  grid-area: beneficiary; }

.styles_currentOpt__2sdYW {
  grid-area: option; }

.styles_marketVal__XQrSW {
  grid-area: marketVal; }

.styles_groupAvailableBalance__2gfxI {
  grid-area: groupBalance; }

/* Non-grid styles */
.styles_acctNum__3r6by,
.styles_acctType__1z-hX,
.styles_accountOwner__b-A4c,
.styles_availableBalance__6YhIX,
.styles_beneficiary__3-ziW,
.styles_currentOpt__2sdYW,
.styles_groupAvailableBalance__2gfxI,
.styles_marketVal__XQrSW {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_detailsTitle__xGEUQ {
  font-weight: bold;
  margin: 5px 0;
  text-align: center; }

.styles_availableBalanceInfo__1gAfd,
.styles_groupAvailableBalanceInfo__15bkD {
  display: flex;
  flex-direction: row;
  align-items: center; }

@media (max-width: 1200px) {
  .styles_cardContentContainerNonProportional__1xtS0 {
    grid-template-columns: 100%;
    grid-template-areas: 'accountTitle' 'accountOwner' 'beneficiary' 'acctNum' 'acctType' 'option' 'marketVal' 'availBalance'; }
  .styles_cardContentContainerProportional__6nmzC {
    grid-template-columns: 100%;
    grid-template-areas: 'accountTitle' 'accountOwner' 'beneficiary' 'acctNum' 'acctType' 'groupBalance'; } }

/*
*
* Withdrawals Styles
*
*/
.styles_container__WHlrl {
  display: grid;
  grid-template-columns: auto minmax(0px, 650px) minmax(0px, 650px) auto;
  grid-template-areas: '. pageNav pageNav .' '. warning warning .' '. details details .' '. new new .';
  grid-column-gap: 30px;
  grid-row-gap: 10px; }

.styles_newWithdrawals__dNW3R {
  grid-area: new; }

.styles_pageNav__odNPK {
  grid-area: pageNav; }

.styles_tradeCutoffWarning__3jC9T {
  grid-area: warning; }

.styles_withdrawalDetails__iuzC9 {
  grid-area: details; }

.styles_tradeCutoffWarning__3jC9T {
  display: flex;
  justify-content: center;
  padding: 10px;
  background: var(--warn); }

@media (max-width: 1200px) {
  .styles_container__WHlrl {
    grid-column-gap: 10px; } }

/*
*
* Step1Amount Styles
*
*/
.styles_step1Container__1IfbL {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_radioContainer__32gBz {
  display: flex;
  flex-direction: column; }

.styles_amountAccordion__2I5Ab {
  min-width: 300px; }

.styles_stepperButtons__2WsyR {
  display: flex;
  margin-top: 25px;
  justify-content: center; }

.styles_stepperButtons__2WsyR button {
  margin-right: 10px; }

.styles_stepperButtons__2WsyR button:last-child {
  max-width: 50px; }

.styles_partialBalanceMessage__VHOgr {
  display: flex;
  flex-direction: column;
  font-size: small;
  max-width: 250px; }

.styles_partialBalanceMessage__VHOgr > ul {
  margin-top: 0px; }

.styles_partialBalanceMessage__VHOgr > ul > li:nth-child(2) {
  margin-top: 12px; }

.styles_datepickerContainer__1G8L1 span {
  margin-left: 5px; }

/*
*
* Step2Recipient Styles
*
*/
.styles_container__3cvB6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.styles_stepperButtons__3K4K6 {
  display: flex;
  margin-top: 25px;
  justify-content: center; }

.styles_stepperButtons__3K4K6 button {
  margin-right: 10px; }

.styles_stepperButtons__3K4K6 button:last-child {
  max-width: 50px; }

/*
*
* Review Styles
*
*/
/*
*
* SASS config
*
*/

.styles_reviewContainer__17qxg {
  display: grid;
  grid-template-columns: 40% auto 40%;
  grid-template-areas: 'amount . recipient' 'note note note';
  justify-content: center;
  justify-items: center; }
  @media (max-width: 767px) {
    .styles_reviewContainer__17qxg {
      grid-template-columns: 100%;
      grid-template-areas: 'amount' 'recipient' 'note'; } }

.styles_recipient__3zUji {
  grid-area: recipient; }
  @media (max-width: 767px) {
    .styles_recipient__3zUji {
      text-align: center; } }

.styles_amount__2JZsP {
  grid-area: amount; }
  @media (max-width: 767px) {
    .styles_amount__2JZsP {
      text-align: center; } }

.styles_fullBalanceNote__2YAMc {
  grid-area: note;
  margin-top: 15px; }

.styles_recipient__3zUji h2,
.styles_amount__2JZsP h2 {
  font-size: large; }

.styles_recipient__3zUji p,
.styles_amount__2JZsP p {
  margin: 0px; }

/*
*
* Step3Terms Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__1A3mQ {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.styles_termsContainer__cNNx6 {
  max-width: 800px;
  margin-top: 50px; }
  @media (max-width: 767px) {
    .styles_termsContainer__cNNx6 {
      width: 100%; } }

.styles_termsContainer__cNNx6 h1 {
  font-size: x-large;
  text-align: center; }

.styles_termsContainer__cNNx6 h2 {
  font-size: medium;
  text-align: center; }

.styles_stepperButtons__3GVGs {
  display: flex;
  margin-top: 25px;
  justify-content: center; }

.styles_stepperButtons__3GVGs button {
  margin-right: 10px; }

.styles_stepperButtons__3GVGs button:last-child {
  max-width: 50px; }

.styles_form__GFrRi {
  display: flex;
  flex-direction: column;
  align-items: center; }

/*
*
* Step4Print Styles
*
*/
.styles_container__1KqrK h1 {
  font-size: x-large;
  text-align: center; }

.styles_timestamp__2OTMN {
  text-align: center; }

.styles_stepperButtons__37i9h {
  display: flex;
  margin-top: 25px;
  justify-content: center; }

.styles_stepperButtons__37i9h button {
  margin-right: 10px;
  max-width: 50px; }

.styles_createdDate__3C4Ia {
  font-weight: bold;
  font-size: large; }

/*
*
* WithdrawalRequests Styles
*
*/
/*
*
* SASS config
*
*/

.styles_breadcrumbs__hZAAB {
  margin-bottom: 15px; }

.styles_container__2w_-O,
.styles_stepperContent__1hoQk {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (max-width: 767px) {
    .styles_container__2w_-O,
    .styles_stepperContent__1hoQk {
      margin: 8px;
      align-items: center; } }

.styles_stepperContent__1hoQk {
  align-items: center;
  padding-bottom: 25px; }

.styles_accountDetailsContainer__3vyRC {
  display: flex;
  flex-direction: column;
  text-align: center; }

.styles_accountDetailsContainer__3vyRC h2 {
  font-weight: bold;
  margin-bottom: 0px;
  text-align: center;
  font-size: medium; }

.styles_accountDetailsContainer__3vyRC p {
  margin: 3px; }

.styles_availableForWithdrawal__2CM0U {
  display: flex;
  align-items: center; }

.styles_availableForWithdrawal__2CM0U span {
  margin-left: 3px;
  align-self: flex-end; }

.styles_loader__3iKY1 {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center; }

/*
*
* SASS config
*
*/

.styles_divider__IHmkZ {
  border: 1px solid #F5F5F4; }

.styles_closeIcon__19d-Z {
  text-align: right; }

.styles_learnMoreInfoContainer__3R7Nu {
  padding: 20px;
  padding-top: 10px; }

.styles_learnMoreInfo__2PTOd {
  font-size: small;
  margin-left: 10%;
  margin-right: 10%; }

.styles_learnMoreInfoHeader__BiSDP {
  text-align: center;
  margin: 25px 0px; }

/*
*
* SASS config
*
*/

.styles_learnMoreInfoContainer__2uqR4 {
  padding: 20px;
  padding-top: 10px; }

.styles_divider__4JYad {
  border: 1px solid #F5F5F4; }

.styles_closeIcon__AQ_Wm {
  text-align: right; }

.styles_learnMoreInfo__iKZbf {
  font-size: small;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 25px; }

.styles_learnMoreInfoHeader__1nGo7 {
  text-align: center;
  margin: 10px 0px; }

.styles_learnMoreInfoText__1EV7t {
  margin: 0 10%;
  padding-bottom: 25px; }

.styles_learnMoreInfoText_footer__dtf-O {
  margin: 25px 10%; }

/*
*
* SASS config
*
*/

@media (max-width: 767px) {
  .styles_validateDepositsText__2EpMK {
    font-size: small; } }

.styles_amountInputs__ZtTiC {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 20px 50px; }
  @media (max-width: 767px) {
    .styles_amountInputs__ZtTiC {
      flex-direction: column;
      margin: 20px 0px; } }

.styles_amountInput__1Mie5 {
  width: 250px; }
  @media (max-width: 767px) {
    .styles_amountInput__1Mie5 {
      width: 100%; } }

/*
*
* ManageBankAccounts Styles
*
*/
/*
*
* SASS config
*
*/

.styles_noAccounts_container__YEU9S {
  margin-top: 50px; }

.styles_noAccounts_disclaimer__Zs0X_ {
  text-align: center; }

.styles_noAccounts_header__11YzF {
  color: rgba(0, 0, 0, 0.3);
  font-size: xx-large;
  text-align: center; }

.styles_buttons_noAccounts__3RXF2 {
  display: flex;
  justify-content: center; }

.styles_buttons_noAccounts__3RXF2 button {
  margin: 0 10px; }

.styles_bankAccountsSummaryContainer__3aHm3 {
  display: grid;
  grid-template-columns: 100%;
  margin: auto;
  grid-gap: 10px;
  gap: 10px;
  width: 100%; }
  @media (min-width: 1024px) {
    .styles_bankAccountsSummaryContainer__3aHm3 {
      max-width: 1200px; } }

.styles_accountOwnerContainer__2VKdD {
  margin-bottom: 30px; }

.styles_addBankButtonContainer_hasAccounts__3TvRm {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px; }

.styles_learnMoreButton_hasAccounts__12GXH {
  margin: 20px 0 0 0;
  text-align: center; }

.styles_buttonsContainer_noAccounts__3gU36 {
  text-align: right;
  padding-right: 20px;
  margin-bottom: 20px; }

.styles_bankDetailsContainer__3nNLW {
  display: flex;
  justify-content: flex-start; }

.styles_icon__1SzJy {
  display: flex;
  font-size: xxx-large;
  align-self: center;
  margin-right: 15px;
  color: rgba(0, 0, 0, 0.34); }

.styles_bankDetails__12PF1 {
  display: flex;
  justify-content: flex-start; }

.styles_lpoaSwitch__35U4F {
  margin-right: 25px; }
  @media (max-width: 1023px) {
    .styles_lpoaSwitch__35U4F {
      margin-right: 0px; } }

.styles_bankDetails__12PF1 p {
  margin: 3px 10px 3px 3px; }
  @media (max-width: 1023px) {
    .styles_bankDetails__12PF1 p {
      font-size: x-small; } }

.styles_bankName__2d7qw,
.styles_bankNameSmall__1nner {
  color: rgba(0, 0, 0, 0.7);
  font-size: small;
  font-weight: bolder;
  margin-right: 10px; }

.styles_nickName__3n8bq {
  color: rgba(0, 0, 0, 0.7);
  font-size: small;
  font-weight: bolder;
  margin-left: 4px; }

.styles_bankAccountType__3WZFL,
.styles_bankAccountTypeSmall__3G3LN {
  font-size: small;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 10px;
  margin-left: 10px; }

.styles_validatedStatus__17Pds,
.styles_validatedStatusSmall__MO0ne {
  color: #73beb1;
  font-size: small;
  margin-right: 10px;
  margin-left: 10px; }

.styles_notValidatedStatus__MoihX,
.styles_notValidatedStatusSmall__12UYH {
  color: #bd3c12;
  font-size: small;
  margin-right: 10px;
  margin-left: 10px; }

.styles_accessCardReqsContainer__CxjUl {
  display: flex;
  flex-wrap: wrap;
  font-size: small; }
  @media (max-width: 767px) {
    .styles_accessCardReqsContainer__CxjUl {
      flex-direction: column; } }

.styles_accessCardReqs__2Nx9n {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  @media (max-width: 1023px) {
    .styles_accessCardReqs__2Nx9n {
      flex-direction: column; } }

.styles_accessCardReqs__2Nx9n li {
  margin-left: 40px;
  color: rgba(0, 0, 0, 0.6); }
  @media (max-width: 767px) {
    .styles_accessCardReqs__2Nx9n li {
      font-size: xx-small;
      margin-left: 15px; } }

.styles_accessCardLink__3TjSQ {
  color: #0072CF;
  cursor: pointer;
  display: flex; }

.styles_accessCardLink__3TjSQ:hover {
  text-decoration: underline; }

.styles_paperContainer__28ibA {
  width: 100%; }

.styles_paperTitle__2s89Q {
  background-color: #0072CF;
  color: #FFFFFF;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px 4px 0px 0px;
  text-transform: capitalize;
  letter-spacing: 3px; }

.styles_paperBodyContainer__1lVrZ {
  padding: 20px; }

.styles_paperBodySection__C0aKU {
  display: flex;
  justify-content: space-between; }

.styles_paperTopLabel__30mWZ,
.styles_labelTILE__3fW4Z {
  color: rgba(0, 0, 0, 0.54);
  font-size: small;
  margin-bottom: 3px; }

@media (max-width: 767px) {
  .styles_labelTILE__3fW4Z {
    font-size: x-small; } }

.styles_paperBottomLabel__CIfRA {
  margin: 0px;
  font-size: xx-large;
  text-transform: uppercase; }

.styles_paperButtons__i7ltv {
  display: flex;
  justify-content: flex-end;
  margin: auto 0; }

.styles_paperButtons__i7ltv button {
  text-decoration: none;
  font-weight: bold;
  font-size: small;
  margin-right: 3px;
  margin-left: 3px; }

.styles_paperDivider__2nD8Z {
  border: 1px solid #F5F5F4; }

/*
*
* AddNonAgentAccount Styles
*
*/
/*
*
* SASS config
*
*/

@media (max-width: 767px) {
  .styles_editCertificateText__1bzfS {
    font-size: small; } }

.styles_fromNameInput__2CL5n {
  display: flex;
  flex-direction: row;
  margin: 20px 50px; }
  @media (max-width: 767px) {
    .styles_fromNameInput__2CL5n {
      flex-direction: column;
      margin: 20px 0px; } }

/*
*
* Documents Styles
*
*/
/*
*
* SASS config
*
*/

.styles_documentsContainer__jWmZs {
  max-width: 1400px;
  margin: auto; }

@media (max-width: 767px) {
  .styles_documentsContainer__jWmZs table td,
  .styles_documentsContainer__jWmZs table th {
    padding: 6px 4px;
    font-size: x-small; } }

.styles_TableToolbar_toolbarContainer__2p8n5 {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.styles_TableToolbar_iconMenu__2nQBv {
  display: flex; }

/*
*
* SASS config
*
*/

.styles_instructionsContainer__3wCAX {
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 20px; }
  @media (max-width: 767px) {
    .styles_instructionsContainer__3wCAX {
      font-size: small; } }

.styles_divider__1Pjws {
  border: 1px solid #F5F5F4; }

.styles_closeIcon__2dnD5 {
  text-align: right; }

.styles_instructionsHeader__1ZWuC {
  text-align: center;
  margin: 20px 0px; }

/*
*
* SASS config
*
*/

.styles_terms__1jdOJ {
  font-size: small;
  margin: 0 15px; }
  @media (max-width: 767px) {
    .styles_terms__1jdOJ {
      margin: 0 5px; } }

/*
*
* AddNonAgentAccount Styles
*
*/
/*
*
* SASS config
*
*/

@media (max-width: 767px) {
  .styles_verifyAccountText__1O3uu {
    font-size: small; } }

.styles_verifyInputs__2ufll {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 20px 50px; }
  @media (max-width: 767px) {
    .styles_verifyInputs__2ufll {
      flex-direction: column;
      margin: 20px 0px; } }

.styles_emptyDiv__369Oc {
  width: 10%; }

.styles_verifyTitle__2yjia {
  grid-area: verifyTitle; }

.styles_verifyDetail__EGEyj {
  grid-area: verifyDetail; }

.styles_verifiedAccountDetails__Y_mri {
  margin: 5px 0;
  display: grid;
  grid-template-columns: .5fr 1fr 1fr .5fr;
  grid-column-gap: 20px;
  grid-template-areas: '. verifyTitle verifyDetail .'; }
  @media (max-width: 767px) {
    .styles_verifiedAccountDetails__Y_mri {
      width: 80%;
      margin: 5px auto;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'verifyTitle verifyDetail'; } }

.styles_verifyTitle__2yjia {
  font-weight: bold; }

/*
*
* PayrollContribution Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__bPeBO {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; }

.styles_divider__36FtF {
  border: 1px solid #F5F5F4; }

.styles_payrollAccounts__AIf3v {
  width: 90%;
  margin-bottom: 30px; }

.styles_accountNumberHeader__3CpvX {
  grid-area: accountNumber; }

.styles_beneficiaryNameHeader__27mK9 {
  grid-area: beneficiaryName; }

.styles_inputsHeader__2GsD1 {
  grid-area: inputs; }

.styles_accountNumber__16NDT {
  grid-area: accountNumber; }

.styles_beneficiaryName__2zoEV {
  grid-area: beneficiaryName; }

.styles_inputs__3DCU_ {
  grid-area: inputs; }

.styles_differentAccountButton__3L9AS {
  grid-area: differentAccountButton; }

.styles_totalText__3u8E2 {
  grid-area: totalText; }

.styles_totalAmount__3QnGz {
  grid-area: inputs; }

.styles_employeeNameSection__35X7_ {
  grid-area: employeeNameSection; }

.styles_employerInput__16poe {
  grid-area: employerInput; }

.styles_payPeriodContributionInput___lN4o {
  grid-area: payPeriodContributionInput; }

.styles_stateInput__u1YsC {
  grid-area: stateInput; }

.styles_employeeSection__1Xsi_ {
  width: 90%;
  display: grid;
  grid-template-columns: 20% 25% 25% 25% 5%;
  grid-column-gap: 5px;
  grid-template-areas: 'employeeNameSection employeeNameSection employerInput stateInput                 .' '.                   .                   .             payPeriodContributionInput .';
  align-items: center;
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .styles_employeeSection__1Xsi_ {
      grid-template-columns: 100%;
      grid-template-areas: 'employeeNameSection' 'employerInput' 'stateInput' 'payPeriodContributionInput'; } }

.styles_headers__18lCE,
.styles_payrollAccountRow__3Ks8J {
  display: grid;
  grid-template-columns: 20% 25% 25% 25% 5%;
  grid-column-gap: 5px;
  grid-template-areas: 'accountNumber beneficiaryName . inputs .';
  align-items: center; }
  @media (max-width: 767px) {
    .styles_headers__18lCE,
    .styles_payrollAccountRow__3Ks8J {
      grid-template-columns: 25% 40% 35%;
      grid-template-areas: 'accountNumber beneficiaryName inputs';
      font-size: small; } }

.styles_totalRow__3jKAC {
  display: grid;
  grid-template-columns: 20% 25% 25% 25% 5%;
  grid-column-gap: 5px;
  grid-template-areas: 'differentAccountButton differentAccountButton totalText inputs .';
  align-items: center; }
  @media (max-width: 767px) {
    .styles_totalRow__3jKAC {
      grid-template-columns: 25% 40% 35%;
      grid-template-areas: 'totalText totalText inputs' 'differentAccountButton differentAccountButton differentAccountButton'; } }

@media (max-width: 767px) {
  .styles_differentAccountButton__3L9AS {
    text-align: center; } }

.styles_currentAllocationText__UIulz {
  grid-area: currentAllocationText; }

.styles_reviewEmployee__3ezWP {
  grid-area: reviewEmployee; }

.styles_reviewEmployer__3ae3B {
  grid-area: reviewEmployer; }

.styles_reviewAmount__3iLP- {
  grid-area: reviewAmount; }

.styles_reviewRouting__3FLVg {
  grid-area: reviewRouting; }

.styles_reviewBankAccount__3q03T {
  grid-area: reviewBankAccount; }

.styles_reviewBankAccountType__Mj5Xo {
  grid-area: reviewBankAccountType; }

.styles_reviewDetails__2DnQ9 {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 3px;
  grid-template-areas: '. currentAllocationText currentAllocationText .' '. reviewEmployee reviewEmployer .' '. reviewBankAccount reviewBankAccountType .' '. reviewRouting reviewAmount .'; }
  @media (max-width: 767px) {
    .styles_reviewDetails__2DnQ9 {
      font-size: small;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 3px;
      grid-template-areas: 'currentAllocationText currentAllocationText' 'reviewEmployee reviewEmployer' 'reviewBankAccount reviewBankAccountType' 'reviewRouting reviewAmount'; } }

.styles_accountListLabels__3Pqr- {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .styles_accountListLabels__3Pqr- {
      font-size: small; } }

.styles_topLabel__1ey-F {
  color: rgba(0, 0, 0, 0.54);
  font-size: small;
  margin-bottom: 3px;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .styles_topLabel__1ey-F {
      font-size: x-small; } }

.styles_reviewDetail__vQs9l {
  font-size: large;
  margin: 0; }
  @media (max-width: 767px) {
    .styles_reviewDetail__vQs9l {
      font-size: medium; } }

.styles_employeeName__3t3gc {
  font-size: xx-large;
  margin: 0; }
  @media (max-width: 767px) {
    .styles_employeeName__3t3gc {
      font-size: x-large;
      margin-bottom: 10px; } }

.styles_accountNumberHeader__3CpvX,
.styles_beneficiaryNameHeader__27mK9 {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold; }

.styles_inputsHeader__2GsD1 {
  display: flex; }
  @media (max-width: 767px) {
    .styles_inputsHeader__2GsD1 {
      flex-direction: column; } }

.styles_typeInputButton__3gDZb {
  width: 50%; }
  @media (max-width: 767px) {
    .styles_typeInputButton__3gDZb {
      width: 100%; } }

.styles_totalText__3u8E2 {
  text-align: right;
  padding-right: 10%;
  font-weight: bold; }

.styles_checkbox__3BNXO {
  width: 300px;
  text-align: center; }

.styles_checkBoxLabel__3XZUw {
  cursor: text; }

.styles_checkBoxLabel__3XZUw > strong {
  color: #0072CF;
  cursor: pointer; }

.styles_termsButton__3bee2 {
  text-align: center;
  padding-bottom: 20px; }

.styles_buttonsContainer__23RzS {
  width: 280px;
  margin: 10px 0;
  text-align: center; }

.styles_instructionsButton__tQ2Eo {
  text-align: center;
  padding: 20px;
  margin-bottom: 10px; }

@media (max-width: 767px) {
  .styles_importantText__2wy-P {
    font-size: small; } }

/*
*
* AddIp Styles
*
*/
.styles_termsContainer__2kogJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px; }

.styles_termsContainer__2kogJ button {
  margin: 8px 0px; }

.styles_showTerms__3gkG4 {
  max-height: 1000px;
  transition: all .5s ease;
  overflow: visible; }

.styles_hideTerms__3LJpv {
  max-height: 0px;
  transition: all .5s ease;
  overflow: hidden; }

/*
*
* LearnMore Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__1564X {
  padding: 20px;
  display: flex;
  flex-direction: column; }

.styles_container__1564X button {
  align-self: flex-end; }

.styles_container__1564X h3 {
  text-align: center; }

.styles_container__1564X p {
  margin-left: 250px;
  margin-right: 250px; }
  @media (max-width: 1023px) {
    .styles_container__1564X p {
      margin-left: 50px;
      margin-right: 50px; } }
  @media (max-width: 767px) {
    .styles_container__1564X p {
      margin-left: 0px;
      margin-right: 0px; } }

/*
*
* InterestedParties Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__2WdSL {
  display: flex;
  flex-direction: column; }

.styles_header__1UaQg {
  background-color: #0072CF;
  color: #FFFFFF;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px 4px 0px 0px;
  text-transform: capitalize;
  letter-spacing: 3px; }

.styles_row__1S15v {
  display: grid;
  padding: 15px;
  grid-row-gap: 15px; }
  @media (min-width: 1024px) {
    .styles_row__1S15v {
      grid-template-columns: 15% auto 20% auto 10% auto 20% auto 10% auto 15%;
      grid-template-areas: 'name . emailAddress . pin . status . accounts . actions';
      align-items: center; } }
  @media (max-width: 1023px) {
    .styles_row__1S15v {
      grid-template-areas: 'name emailAddress' 'pin status' 'accounts accounts' 'actions actions'; } }
  @media (max-width: 767px) {
    .styles_row__1S15v {
      grid-template-areas: 100%;
      grid-template-areas: 'name' 'emailAddress' 'pin' 'status' 'accounts' 'actions'; } }

.styles_emailAddress__37Se2 {
  grid-area: emailAddress;
  overflow-x: hidden; }

.styles_name___0l4w {
  grid-area: name; }

.styles_pin__3fA2o {
  grid-area: pin; }

.styles_status__3iXad {
  grid-area: status; }

.styles_accounts__1gDty {
  grid-area: accounts; }

.styles_actions__1gSv8 {
  grid-area: actions; }
  @media (min-width: 1024px) {
    .styles_actions__1gSv8 {
      justify-self: flex-end;
      align-self: center; } }

.styles_label__1nyCK {
  color: rgba(0, 0, 0, 0.3);
  font-size: small;
  text-transform: uppercase; }

.styles_data__1N_6A {
  margin: 0px;
  font-size: x-large;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .styles_data__1N_6A {
      font-size: large; } }

.styles_addIpButton__vAzPI {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px; }

.styles_ipButtons__3jLmU button:first-child {
  margin-right: 25px; }

.styles_newIp__dHk_D {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.styles_newIp__dHk_D h1 {
  color: rgba(0, 0, 0, 0.3);
  font-size: xx-large;
  text-align: center;
  font-weight: normal; }

.styles_infoButtons__2UNf_ {
  display: flex;
  justify-content: center;
  padding: 20px; }

.styles_infoButtons__2UNf_ button {
  margin: 5px; }

.styles_accountsTableContainer__1flh1 {
  display: flex;
  flex-direction: column;
  margin-top: 25px; }

/*
*
* ChangeEmailWhileBlocked Styles
*
*/
.styles_emailAddress__LrtKt {
  text-align: center;
  font-weight: bold; }

/*
*
* VerifyEmail Styles
*
*/
/*
*
* SASS config
*
*/

@media (max-width: 1023px) {
  .styles_container__3PaGt {
    width: 80%; } }

@media (max-width: 767px) {
  .styles_container__3PaGt {
    width: 90%; } }

.styles_emailAddress__3o-1K {
  padding: 20px;
  font-weight: bold; }

.styles_containerCard__16F5k {
  width: 90%;
  margin: auto; }

/*
*
* SASS config
*
*/

.styles_ChallengeQuestions__1ZwQR {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .25fr .25fr .5fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas: "question-label" "question" "answer";
  width: 50%;
  min-width: 50%;
  max-width: 70%;
  margin: auto;
  text-align: left; }

.styles_questionLabel__1Vmlv {
  grid-area: question-label;
  color: #999; }

.styles_question__1cCuF {
  grid-area: question; }

.styles_answer__3qO-r {
  grid-area: answer;
  margin-bottom: 30px;
  text-align: center; }

.styles_DOBcontainer__nFHyF {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr 1fr .5fr 1fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas: "ao-name-label" "ao-name" "ao-dob-label" "ao-dob";
  width: 50%;
  min-width: 40%;
  max-width: 70%;
  margin: auto;
  text-align: left; }

.styles_aoNameLabel__1jE83 {
  grid-area: ao-name-label;
  color: #999; }

.styles_aoName__rhS9I {
  grid-area: ao-name;
  font-weight: bold;
  margin-bottom: 15px; }

.styles_aoDOBLabel__HFtiN {
  grid-area: ao-dob-label;
  color: #999; }

.styles_aoDOB__2gH_9 {
  grid-area: ao-dob;
  font-weight: bold; }

.styles_DOB__nDd_2 {
  padding-bottom: 15px;
  margin: auto; }
  @media (max-width: 767px) {
    .styles_DOB__nDd_2 {
      width: 100%; } }

.styles_errorMsg__3RrHN {
  color: #E50000; }

/*
*
* SASS config
*
*/

.styles_container__RbSwv {
  width: 60%;
  margin: auto; }
  @media (max-width: 1023px) {
    .styles_container__RbSwv {
      width: 90%; } }
  @media (max-width: 767px) {
    .styles_container__RbSwv {
      width: 350px;
      margin-top: -70px; } }

.styles_body__21-ZA {
  width: 90%;
  margin: auto;
  padding-bottom: 20px; }

.styles_logo__ODuhn {
  padding: 25px;
  text-align: center; }

.styles_instructions__39WJ2 {
  margin: 15px 15px 0 15px; }

/*
*
* AccountOwnerStep Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__2nhhG {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_form__t9HKd {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'info' 'addresses' 'resident' 'buttons';
  justify-items: center;
  margin: 25px; }
  @media (min-width: 1024px) {
    .styles_form__t9HKd {
      grid-template-columns: minmax(0px, 500px) minmax(0px, 500px);
      grid-template-areas: 'info addresses' 'resident resident' 'buttons buttons';
      grid-column-gap: 50px; } }
  @media (max-width: 767px) {
    .styles_form__t9HKd {
      margin: 10px; } }

.styles_infoContainer__3xCtP {
  width: 100%;
  grid-area: info; }

.styles_addressesContainer__2g64N {
  width: 100%;
  grid-area: addresses; }

.styles_residentContainer__3w_5x {
  margin-top: 25px;
  grid-area: resident; }

.styles_stepperButtons__2ZPrY {
  margin-top: 25px;
  grid-area: buttons; }
  @media (max-width: 1023px) {
    .styles_stepperButtons__2ZPrY {
      margin: 25px 0px; } }

.styles_headerContainer__mRfzG {
  display: flex;
  align-items: center; }

.styles_headerContainer__mRfzG h1 {
  margin-right: 8px; }

.styles_ssnContainer__3x9Tx {
  display: flex;
  flex-direction: column; }

.styles_ssnInputs__2nOdh {
  display: flex;
  align-items: center; }

/*
*
* BeneficiaryStep Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__2VQG2 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_form__2OwfF {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'info' 'addresses' 'resident' 'buttons';
  justify-items: center;
  margin: 25px; }
  @media (min-width: 1024px) {
    .styles_form__2OwfF {
      grid-template-columns: minmax(0px, 500px) minmax(0px, 500px);
      grid-template-areas: 'info addresses' 'resident resident' 'buttons buttons';
      grid-column-gap: 50px; } }
  @media (max-width: 767px) {
    .styles_form__2OwfF {
      margin: 10px; } }

.styles_infoContainer__1RTPo {
  width: 100%;
  grid-area: info; }

.styles_addressesContainer__2uVBl {
  width: 100%;
  grid-area: addresses; }

.styles_residentContainer__3rwkF {
  margin-top: 25px;
  grid-area: resident; }

.styles_stepperButtons__36lxe {
  margin-top: 25px;
  grid-area: buttons; }
  @media (max-width: 1023px) {
    .styles_stepperButtons__36lxe {
      margin: 25px 0px; } }

.styles_headerContainer__N_H7b {
  display: flex;
  align-items: center; }

.styles_headerContainer__N_H7b h1 {
  margin-right: 8px; }

@media (max-width: 1023px) {
  .styles_addressCheckbox__3_Pgq {
    margin: 12px 0px; } }

.styles_ssnContainer__3R0Rj {
  display: flex;
  flex-direction: column; }

.styles_ssnInputs__3t63G {
  display: flex;
  align-items: center; }

/*
*
* Summary Styles
*
*/
/*
*
* SASS config
*
*/

.styles_accountOwnerHeader__kNRPO,
.styles_beneficiaryHeader__3tW_f,
.styles_investmentOptionHeader__3Kw83 {
  display: flex;
  align-items: center;
  justify-content: center; }

.styles_fieldContainer__24XQm {
  margin-right: 22px; }

.styles_fieldContainer__24XQm h3 {
  font-weight: 500; }

.styles_fieldContainer__24XQm h4 {
  color: #6B6E6F;
  font-size: small;
  text-transform: none;
  font-weight: 500;
  margin-bottom: -8px; }

.styles_fieldContainer__24XQm p,
.styles_investmentOption__332N9 p {
  font-size: x-large;
  margin: 12px 0px; }
  @media (max-width: 767px) {
    .styles_fieldContainer__24XQm p,
    .styles_investmentOption__332N9 p {
      font-size: large; } }

.styles_aoNameContainer__3c0xF,
.styles_beneNameContainer__2xOYx {
  display: flex; }
  @media (max-width: 767px) {
    .styles_aoNameContainer__3c0xF,
    .styles_beneNameContainer__2xOYx {
      flex-direction: column; } }

.styles_reviewStepDivider__1HvcG {
  width: 100%;
  border: 1px solid #6B6E6F;
  margin: 18px 0px 8px 0px; }

.styles_stepperButtons__LbZkH {
  margin-top: 25px;
  display: flex;
  justify-content: space-around; }

/****** GRID STYLES ******/
.styles_headerContainer__3zMzq {
  grid-area: header; }

.styles_investmentOptionContainer__1caMd {
  grid-area: investment; }

.styles_accountOwnerContainer__vBrf8 {
  grid-area: aoContainer; }

.styles_beneficiaryContainer__1Os69 {
  grid-area: beneContainer; }

.styles_promoCode__2dlTA {
  grid-area: promoCode; }

.styles_stepperButtons__LbZkH {
  grid-area: buttons; }

.styles_container__2hqIM {
  display: grid;
  justify-items: center;
  align-items: start; }
  @media (min-width: 1024px) {
    .styles_container__2hqIM {
      grid-template-columns: minmax(0px, 750px) minmax(0px, 750px);
      grid-template-areas: 'promoCode promoCode' 'header header' 'investment investment' 'aoContainer beneContainer' 'buttons buttons'; } }
  @media (max-width: 1023px) {
    .styles_container__2hqIM {
      grid-template-areas: 'promoCode' 'header' 'investment' 'aoContainer' 'beneContainer' 'buttons'; } }

.styles_accountOwnerHeader__kNRPO {
  grid-area: accountOwnerHeader; }

.styles_accountOwnerInfo__DUbiK {
  grid-area: aoInfo; }

.styles_accountOwnerAddress__3BMaM {
  grid-area: aoAddress; }

.styles_accountOwnerContainer__vBrf8 {
  display: grid;
  justify-items: center;
  justify-content: center; }
  @media (min-width: 1024px) {
    .styles_accountOwnerContainer__vBrf8 {
      grid-template-columns: minmax(0px, 650px) minmax(0px, 650px);
      grid-template-areas: 'accountOwnerHeader accountOwnerHeader' 'aoInfo aoAddress';
      border-right: 1px solid #F5F5F4;
      margin-left: 20px; } }
  @media (max-width: 1023px) {
    .styles_accountOwnerContainer__vBrf8 {
      grid-template-columns: minmax(0px, 45%) 25px minmax(0px, 45%);
      grid-template-areas: 'accountOwnerHeader accountOwnerHeader accountOwnerHeader' 'aoInfo . aoAddress'; } }
  @media (max-width: 767px) {
    .styles_accountOwnerContainer__vBrf8 {
      grid-template-columns: 100%;
      grid-template-areas: 'accountOwnerHeader' 'aoInfo' 'aoAddress'; } }

.styles_beneficiaryHeader__3tW_f {
  grid-area: beneficiaryHeader; }

.styles_beneficiaryInfo__3zh36 {
  grid-area: beneInfo; }

.styles_beneficiaryAddress__3xq66 {
  grid-area: beneAddress; }

.styles_beneficiaryContainer__1Os69 {
  display: grid;
  justify-items: center;
  justify-content: center; }
  @media (min-width: 1024px) {
    .styles_beneficiaryContainer__1Os69 {
      grid-template-columns: minmax(0px, 650px) minmax(0px, 650px);
      grid-template-areas: 'beneficiaryHeader beneficiaryHeader' 'beneInfo beneAddress'; } }
  @media (max-width: 1023px) {
    .styles_beneficiaryContainer__1Os69 {
      grid-template-columns: minmax(0px, 45%) 25px minmax(0px, 45%);
      grid-template-areas: 'beneficiaryHeader beneficiaryHeader beneficiaryHeader' 'beneInfo . beneAddress'; } }
  @media (max-width: 767px) {
    .styles_beneficiaryContainer__1Os69 {
      grid-template-columns: 100%;
      grid-template-areas: 'beneficiaryHeader' 'beneInfo' 'beneAddress'; } }

.styles_accountOwnerInfo__DUbiK,
.styles_accountOwnerAddress__3BMaM,
.styles_beneficiaryInfo__3zh36,
.styles_beneficiaryAddress__3xq66 {
  display: flex;
  flex-direction: column; }

.styles_investmentOption__332N9 {
  margin-right: 0px;
  text-align: center; }

/*
*
* ConfirmationStep Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__LfLLF {
  padding: 25px;
  position: relative; }

.styles_headerContainer__1FLCZ {
  text-align: center;
  margin-bottom: 50px; }

.styles_stepperButtons__2S0OC {
  margin-top: 25px;
  display: flex;
  justify-content: center; }

.styles_stepperButtons__2S0OC button:first-child {
  margin-right: 10px; }

@media (min-width: 1024px) {
  .styles_printButton__U2F0C {
    position: absolute;
    top: 25px;
    right: 25px; } }

@media (max-width: 1023px) {
  .styles_printButton__U2F0C {
    text-align: center; } }

/*
*
* InvestmentOptionStep Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__2Q85E {
  max-width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.styles_container__2Q85E form {
  margin: 25px; }

.styles_stepperButtons__2yViB {
  margin-top: 25px;
  display: flex;
  justify-content: center; }

.styles_expandedDetails__14s8i p:first-child {
  margin-top: 0px; }

.styles_formError__jrg5K {
  color: #E50000;
  text-align: center;
  margin-top: 25px; }

.styles_generalInfo__2Z8Du {
  margin: 25px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_customizedButtons__29qty {
  margin: 25px;
  display: flex;
  justify-content: flex-end; }

.styles_customizedButtons__29qty button {
  margin-right: 8px; }

.styles_customizedOptionsContainer__2k-5a {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.styles_createCustomizedButtons__b5SnA {
  display: flex;
  justify-content: space-around; }

.styles_radioButtonContainer__kfxS7 {
  display: flex;
  justify-content: space-between; }

.styles_radioLabelContainer__UNbhE,
.styles_radioLabel__2GIY1,
.styles_customizedBtnInfo__2QRc6 {
  display: flex;
  align-items: center; }

.styles_radioLabel__2GIY1 div {
  margin-right: 5px; }

.styles_enrollmentPerformanceAndDetails__3e5MJ {
  display: flex;
  justify-content: space-between;
  align-items: center; }

/*
*
* ReviewStep Styles
*
*/
.styles_container__2qFU- {
  padding: 25px; }

.styles_headerContainer__ri9on {
  text-align: center; }

.styles_stepperButtons__342Mc {
  margin-top: 25px;
  display: flex;
  justify-content: center; }

/*
*
* TermsConditionsStep Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__2rpng {
  max-width: 750px;
  margin: 25px;
  display: flex;
  flex-direction: column;
  position: relative; }

.styles_stepperButtons__3xEoZ {
  padding: 25px 0px;
  display: flex;
  justify-content: center; }

.styles_container__2rpng h1 {
  text-align: center; }

.styles_container__2rpng li {
  line-height: 1.5em;
  margin-top: 10px; }

@media (min-width: 1024px) {
  .styles_printButton__1fexM {
    position: absolute;
    top: 18px;
    right: 0px; } }

@media (max-width: 1023px) {
  .styles_printButton__1fexM {
    align-self: center; } }

/*
*
* Welcome Styles
*
*/
/*
*
* SASS config
*
*/

.styles_contentContainer__vs-4q {
  background-color: #41AD49;
  color: white;
  min-height: 350px; }

.styles_welcomeContainer__tuFoF p {
  line-height: 1.6;
  margin-left: 50px; }

.styles_welcomeContainer__tuFoF h1 {
  font-size: x-large;
  font-weight: normal;
  margin-top: 30px; }

.styles_stepsContainer__2BfjJ {
  margin-top: 30px; }

.styles_progressContainer__3-w3T {
  display: flex;
  align-items: center;
  margin-left: 50px; }

.styles_progressContainer__3-w3T span {
  margin-right: 8px; }

.styles_progressContainer__3-w3T p {
  margin: 8px 0px; }

.styles_imgContainer__2KkGg {
  display: grid;
  justify-content: center;
  justify-items: center;
  overflow: hidden; }

.styles_picture__2lg_- {
  object-fit: cover;
  min-width: 450px;
  height: 250px;
  filter: brightness(0.5); }
  @media (max-width: 767px) {
    .styles_picture__2lg_- {
      width: 100%; } }

.styles_picture__2lg_-,
.styles_logo__3Da1B {
  grid-area: 1 / 1; }

.styles_logo__3Da1B {
  align-self: center;
  margin: 25px;
  width: auto;
  height: 95px;
  filter: brightness(1); }

.styles_buttonContainer__31JcD {
  display: flex;
  justify-content: center;
  margin: 35px 35px 10px 35px; }

/*
*
* Signup Styles
*
*/
/*
*
* SASS config
*
*/

.styles_container__2OfEh {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  @media (max-width: 767px) {
    .styles_container__2OfEh {
      width: 100%;
      align-items: center; } }

.styles_stepperContentContainer__1a6m- {
  display: flex;
  justify-content: center; }

.styles_deleteButton__10wSz {
  margin-top: 50px;
  align-self: center; }

/*
*
* AddBirthdate Styles
*
*/
.styles_container__2fWTI,
.styles_formContainer__25rl9 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_container__2fWTI {
  margin: -25px 0px 0px -75px; }

.styles_container__2fWTI p:first-child {
  max-width: 650px;
  font-size: large; }

.styles_buttons__LmpHV {
  margin-top: 25px;
  display: flex;
  justify-content: space-around; }

.styles_support__37jgx {
  margin: auto;
  font-size: small;
  text-align: center;
  margin-top: 50px;
  color: #767676; }

.styles_preparingModal__vKm_a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.styles_preparingMessage__1yhUh {
  margin-bottom: 15px; }

.styles_preparingError__1_3jL {
  display: flex;
  width: 100%;
  margin: 4px 0 4px 10px; }

.styles_preparingError__1_3jL > span:first-of-type {
  color: var(--error);
  margin-right: 5px; }

.styles_preparingError__1_3jL > span:last-of-type {
  margin-top: 4px; }

@media (min-width: 1200px) {
  .styles_preparingModal__vKm_a {
    width: 300px;
    min-height: 100px; }
  .styles_preparingMessage__1yhUh {
    font-size: 1.2em; } }

/*
*
* SASS config
*
*/

.styles_container__2Prmw {
  width: 60%;
  margin: auto; }
  @media (max-width: 1023px) {
    .styles_container__2Prmw {
      width: 90%; } }
  @media (max-width: 767px) {
    .styles_container__2Prmw {
      width: 350px; } }

.styles_body__1HLap {
  width: 90%;
  margin: auto;
  text-align: center;
  padding-bottom: 20px; }

.styles_logo__2lDHZ {
  padding: 25px;
  text-align: center; }

.styles_instructions__3FWGd {
  margin: 15px 15px 0 15px; }

.styles_attemptWarning__2lFkr {
  color: #E50000;
  margin: auto;
  width: 60%; }
  @media (max-width: 767px) {
    .styles_attemptWarning__2lFkr {
      width: 95%; } }

/*
*
* SASS config
*
*/

.styles_logo__2T0sw {
  padding: 25px;
  text-align: center; }

.styles_container__2eevl {
  width: 70%;
  margin: auto;
  text-align: center;
  padding-bottom: 20px; }
  @media (max-width: 1023px) {
    .styles_container__2eevl {
      width: 80%; } }
  @media (max-width: 767px) {
    .styles_container__2eevl {
      width: 95%; } }

.styles_msg__3kmuS {
  padding: 40px; }
  @media (max-width: 1023px) {
    .styles_msg__3kmuS {
      padding: 10px; } }
  @media (max-width: 767px) {
    .styles_msg__3kmuS {
      padding: 5px; } }

/*
*
* SASS config
*
*/

.styles_ChallengeQuestions__8of3h {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .25fr .25fr .5fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas: "question-label" "question" "answer";
  width: 50%;
  min-width: 50%;
  max-width: 70%;
  margin: auto;
  text-align: left; }

.styles_questionLabel__3yLFB {
  grid-area: question-label;
  color: #999; }

.styles_question__289NR {
  grid-area: question; }

.styles_answer__3fsjd {
  grid-area: answer;
  margin-bottom: 30px;
  text-align: center; }

.styles_DOBcontainer__2ZJgE {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr 1fr .5fr 1fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas: "ao-name-label" "ao-name" "ao-dob-label" "ao-dob";
  width: 50%;
  min-width: 40%;
  max-width: 70%;
  margin: auto;
  text-align: left; }

.styles_aoNameLabel__2RE-u {
  grid-area: ao-name-label;
  color: #999; }

.styles_aoName__1zUqj {
  grid-area: ao-name;
  font-weight: bold;
  margin-bottom: 15px; }

.styles_aoDOBLabel__1GlYT {
  grid-area: ao-dob-label;
  color: #999; }

.styles_aoDOB__3LK_W {
  grid-area: ao-dob;
  font-weight: bold; }

.styles_DOB__25QSM {
  padding-bottom: 15px;
  margin: auto; }
  @media (max-width: 767px) {
    .styles_DOB__25QSM {
      width: 100%; } }

.styles_container__DC16V {
  width: 60%;
  margin: auto; }
  @media (max-width: 1023px) {
    .styles_container__DC16V {
      width: 90%; } }
  @media (max-width: 767px) {
    .styles_container__DC16V {
      width: 350px; } }

.styles_body__1PVAM {
  width: 90%;
  margin: auto; }

.styles_logo__Lyl0h {
  padding: 25px;
  text-align: center; }

.styles_errorMsg__Yms44 {
  color: #E50000; }

/*
*
* Navigation Styles
*
*/
/*
*
* SASS config
*
*/

.styles_featuresContainer__3q4ro {
  position: relative;
  width: 100%;
  height: 89vh;
  display: flex;
  flex-direction: column; }
  @media (max-width: 767px) {
    .styles_featuresContainer__3q4ro {
      height: 98vh; } }

.styles_featuresChildren__3z1iU,
.styles_featuresChildrenShiftFull__1CPuk,
.styles_featuresChildrenShiftMini__1hmRc {
  padding: 0 10px;
  flex: 1 0 auto;
  transition: all .5s ease;
  margin-top: 20px; }
  @media print {
    .styles_featuresChildren__3z1iU,
    .styles_featuresChildrenShiftFull__1CPuk,
    .styles_featuresChildrenShiftMini__1hmRc {
      margin-top: 5px; } }
  @media (max-width: 767px) {
    .styles_featuresChildren__3z1iU,
    .styles_featuresChildrenShiftFull__1CPuk,
    .styles_featuresChildrenShiftMini__1hmRc {
      margin-top: 85px; } }

.styles_legalFooter__2505r {
  transition: margin-left .5s ease;
  margin-left: 0px; }

.styles_featuresChildrenShiftFull__1CPuk,
.styles_legalFooterShiftFull__diqxZ {
  transition: margin-left .5s ease;
  margin-left: 250px; }
  @media (max-width: 767px) {
    .styles_featuresChildrenShiftFull__1CPuk,
    .styles_legalFooterShiftFull__diqxZ {
      margin-left: 0; } }

.styles_featuresChildrenShiftMini__1hmRc,
.styles_legalFooterShiftMini__2A0sU {
  margin-left: 75px;
  margin-right: 20px; }
  @media (max-width: 767px) {
    .styles_featuresChildrenShiftMini__1hmRc,
    .styles_legalFooterShiftMini__2A0sU {
      margin-left: 0;
      margin-right: 0; } }

/*
*
* AppRoot Styles
*
*/
/*
*
* SASS config
*
*/

* {
  box-sizing: border-box; }

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0; }

body {
  font-family: Roboto, PT Sans, Arial, sans-serif;
  color: #242424;
  font-size: 100%;
  background-color: #FAFAFA; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

::-ms-clear, ::-ms-reveal {
  display: none; }

a, a:visited {
  color: #0059B6; }

a {
  text-decoration: none;
  cursor: pointer; }

a:hover {
  text-decoration: underline; }

:root {
  --primary: #0072CF;
  --accent: #41AD49;
  --accent2: #EA7125;
  --error: #E50000;
  --text: rgba(0, 0, 0, 0.87);
  --warn: #fae500;
  --linkText: #0072CF;
  --lightGray: #F5F5F4; }

.grecaptcha-badge {
  visibility: hidden; }

@media print {
  .hideOnPrint {
    display: none !important; } }

