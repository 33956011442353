/*
*
* Navigation Styles
*
*/
@import 'utils/config/sassconfig';

.featuresContainer {
  position: relative;
  width: 100%;
  height: 89vh;
  display: flex;
  flex-direction: column;

  @include mobile {
    height: 98vh;
  }
}

.featuresChildren,
.featuresChildrenShiftFull,
.featuresChildrenShiftMini {
  padding: 0 10px;
  flex: 1 0 auto;
  transition: all .5s ease;
  margin-top: 20px;

  @media print{
    margin-top: 5px;
  } 

  @include mobile {
    margin-top: 85px;
  }
}

.legalFooter {
  transition: margin-left .5s ease;
  margin-left: 0px;
}

.featuresChildrenShiftFull,
.legalFooterShiftFull {
  transition: margin-left .5s ease;
  margin-left: 250px;

  @include mobile {
    margin-left: 0;
  }
}

.featuresChildrenShiftMini,
.legalFooterShiftMini {
  margin-left: 75px;
  margin-right: 20px;
  
  @include mobile {
    margin-left: 0;
    margin-right: 0;
  }
}
